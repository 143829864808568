import React, { useState, useEffect, useRef, Fragment } from "react";

const LocalParticipant = ({ participant }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [videoOpen, setVideoOpen] = useState(true);


  const videoRef = useRef();
  
  useEffect(() => {
    videoTracks.forEach(track=>{
      if (track.kind === "video") {
        if(!track.isEnabled){
          setVideoOpen(false);
        }else{
          setVideoOpen(true);
        }
        track.on('disabled', () => {
          setVideoOpen(false);
        });
        track.on('enabled', () => {
          setVideoOpen(true);
        });
      }
    })
  }, [videoTracks]);
  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    const trackSubscribed = (track) => {
      
       
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } 
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[videoTracks.length-1];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

 

  if(!participant) return<Fragment/>
  return (
    <div  className={` border h-100 w-100 btn border-5 border-transparent d-inline-block p-cont position-relative`}>
      {/* <h3>{participant.identity}</h3> */}
      <div className={`${videoOpen?'d-none':''} w-100 h-100 d-flex align-items-center justify-content-center text-uppercase fw-bolder  fs-4`}>
          {participant.identity.split(' ').map(name=>name[0]).join('')}
        </div>
        <video className={`${!videoOpen?'d-none':''}  w-100 h-100`} ref={videoRef} autoPlay={true} />
    </div>
  );
};

export default LocalParticipant;