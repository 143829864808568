import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createRef, Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import swal from "sweetalert";
import { useStateWithLocalStorage } from "../../hooks/token_hooks";
import { sendMessageCall, getNewMessagesCall } from "../../network/learner/learner_session_manager";
import { base_url } from "../../network/utils";

const MessagePanel = ({learnerDetails, currentSession, showLiveSession, ...props}) => {
    const [token] = useStateWithLocalStorage("learner_token");

    const [allMessages, setAllMessage] = useState({});

    const messageContainer = createRef();

    const [showMessage, setShowMessage] = useState(false);

    

    useEffect(() => {
        if(!messageContainer.current)return;
        messageContainer.current.scrollTop = messageContainer.current.scrollHeight;
    }, [allMessages,messageContainer]);
    useEffect(() => {
        const getNewMessages = async ()=>{
            if(!currentSession) return;
            let m_key = ''+currentSession.id;
            let currentMessage = {...allMessages[m_key]};
            
            if(!currentMessage.messages){
                currentMessage.lastId = 0;
                currentMessage.messages = [];
            }
            
            await getNewMessagesCall (token, currentSession.id, currentMessage.lastId)
            .then((response)=>{
                // console.log(response.status)
                if(response.ok){
                    return response.json();
                }
                
                
            })
            .then((messages)=>{
                
                if(messages.length > 0){
                    currentMessage.lastId = messages[messages.length-1].id;
                    currentMessage.messages = [...currentMessage.messages, ...messages];
                    setAllMessage({...allMessages, [m_key]:currentMessage});

                    let newMessage = messages.filter(m=>{
                        return m.learner_id !== learnerDetails.id;
                    })
                    if(newMessage.length > 0){
                        swal({
                            text: `${newMessage.length} new message${newMessage.length>1?'s':''}`,
                            icon: 'info',
                            timer: 1000,
                            button: false,
                        });
                    }
                    
                }
                
                
            }).catch((e)=>{
                console.log(e)            
            })
        }
        
        const newMessageInterval= setInterval(() => {
            getNewMessages();
        }, 10000);
        getNewMessages();

        
        
        return () => clearInterval(newMessageInterval);
        }, [allMessages, currentSession, learnerDetails, token]);



    let renderMessages = ()=>{
        // console.log('allMessages', allMessages)
        if(!allMessages[''+currentSession.id] || !allMessages[''+currentSession.id].messages) return;
        return allMessages[''+currentSession.id].messages.map((message)=>{
            if(message.learner_id !== learnerDetails.id) return <LeftMessage
                key={message.id}
                message={message}
                />;
            return <RightMessage
                key={message.id}
                message={message}
            />;
        });
    }
    let chatContainerStyle = {
        right : !showMessage?'-200%':'0px',
    }
    if(!currentSession) return <Fragment/>
    return ( 
        <div className="col-lg-9 col-md-8  pt-1">
            <div style={showLiveSession?chatContainerStyle:{}} className={`card me-md-3 h-100 shadow border-0 ${showLiveSession?'session_on_chat':'main-righter'} `}
                
            >
                <div className="chat-container d-none text-end pe-2" onClick={()=>setShowMessage(state=>!state)}  data-bs-toggle="tooltip" data-bs-placement="left" title="Click to Chat" >
                    
                    <FontAwesomeIcon className={`text-${!showMessage?'primary bounce':'danger'}  mx-2 `}  size="2x" icon="comment-alt"/>
                    {showMessage?
                    <small className="muted d-block fs-7 text-danger shadow border">close chats</small>
                    :
                    <small className="muted d-block fs-7">show chats</small>
                    }
                    
                </div>
                
                <div className="card-body p-1 d-flex flex-column align-items-top justify-content-end" style={{height:'inherit'}}>
                    <div className="flex-grow-1 adjust-max-height" style={{maxHeight:'75vh', overflowY:'scroll'}} ref={messageContainer}>
                        {renderMessages()}
                        
                    </div>
                    <SubmitPanel
                        currentSession={currentSession}
                        
                        messageContainer={messageContainer}
                    />
                </div>
                
            </div>
            
        </div>
    );
}
const LeftMessage = ({message, ...props}) => {
    return ( 
        <div className="mb-3 ps-2">
            <div>
                <div className="border rounded-circle d-inline-block overflow-hidden me-2" style={{width:'35px',}}>
                    <img src={message.learner.img_url? `${base_url}storage/${message.learner.img_url}`:'/avatar.png'} className="w-100" alt="logo" />
                    
                </div>
                <label className="text-muted fs-7">{message.learner.name.split(' ')[0]}</label>
            </div>
            <div className="bg-primary-light text-primary p-2 rounded text-bolder d-inline-block ms-3" style={{backgroundColor:'#95be4620', maxWidth:'75%'}}>
        
                <span>{message.message}</span>
            </div>
            <div className="pt-0">
                <small className="text-muted fs-8"><Moment fromNow>{message.created_at}</Moment></small>
            </div>
        </div>
    );
}

const RightMessage = ({message, ...props}) => {
    return ( 
        <div className="mb-2 pe-2 d-flex  flex-column  align-items-end justify-content-end">
            <div className="bg-primary text-light p-2 rounded  d-inline-block " style={{ maxWidth:'75%'}}>
            {message.message}
            </div>
            <div>
                <small className="text-muted fs-8"><Moment fromNow>{message.created_at}</Moment></small>
            </div>
        </div>
     );
}

const SubmitPanel = ({currentSession, messageContainer, ...props}) => {
    const [token] = useStateWithLocalStorage("learner_token");
    const sendMessage = async (data)=>{
        await sendMessageCall(token, currentSession.id, data)
        .then((response)=>{
            // console.log(response.status)
            if(response.ok){
                return response.json();
            }
            
            
        })
        .then((json_resp)=>{
            // console.log(messageContainer);
            // messageContainer.current.scrollTop = messageContainer.current.scrollHeight;

            
        }).catch((e)=>{
            console.log(e)            
        })
    }

    let handleSubmit = (event)=>{
        event.preventDefault();
        sendMessage(new FormData(event.target));
        event.target.reset();

    }
    return ( 
        <form action="" method='post' className="row m-0" onSubmit={handleSubmit}>
            <div className="col-10">
                <input type="text" name="message" className="form-control" maxLength="255" />
            </div>
            <div className="col-2">
                <button type="submit" className="btn btn-primary text-light">send</button>
            </div>
        </form>
    );
}

export default MessagePanel;