import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { useStateWithLocalStorage } from "../../hooks/token_hooks";
import { learnerViewLesson } from "../../network/learner/learner_manager";

const CoursePanel = ({
  setViewedObject,
  loadingCourse,
  setShowCourseList,
  courses,
  currentLesson,
  setCurrentLesson,
  setCurrentCourseRequirements,
  currentCourseRequirements,
  showCourseList,
  readCourseText,
  setReadCourseText,
  learnerDetails,
  ...props
}) => {
  const [loadingCourseState, setLoadingCourseState] = useState(true);
  const [courseList, setCourseList] = useState([]);
  useEffect(() => {
    if (typeof loadingCourse == "undefined") return;
    setLoadingCourseState(loadingCourse);
  }, [loadingCourse]);

  useEffect(() => {
    setCourseList(courses);
  }, [courses]);

  let handleParentClicked = (event) => {
    setShowCourseList(false);
  };

  let handleHolderClicked = (event) => {
    event.stopPropagation();
  };
  let renderCourseList = () => {
    return courseList.map((course) => {
      return (
        <CourseListItem
          setCurrentCourseRequirements={setCurrentCourseRequirements}
          currentCourseRequirements={currentCourseRequirements}
          setViewedObject={setViewedObject}
          setCurrentLesson={setCurrentLesson}
          currentLesson={currentLesson}
          course={course}
          key={course.id}
          learnerDetails={learnerDetails}
        />
      );
    });
  };
  return (
    <span
      className="dashboard-left-panel col-lg-2 col-md-3 p-0 "
      onClick={handleParentClicked}
      style={{ marginLeft: showCourseList ? "0" : "" }}
    >
      <div
        className=" holder bg-theme-dark text-light noisy text-start px-2 pe-3"
        onClick={handleHolderClicked}
      >
        <h4 className="fs-5 ps-2 pt-2">
          <span>Courses </span>
          <span
            className={`spinner-grow spinner-grow-sm ms-2 ${
              loadingCourseState ? "" : "d-none"
            }`}
            role="status"
            aria-hidden="true"
          ></span>
          {readCourseText ? (
            <FontAwesomeIcon
              onClick={() => setReadCourseText((old_p) => !old_p)}
              className="text-light float-end"
              icon="volume-up"
            />
          ) : (
            <FontAwesomeIcon
              onClick={() => setReadCourseText((old_p) => !old_p)}
              className="text-light float-end"
              icon="volume-mute"
            />
          )}
        </h4>
        <div className="card pb-5 pt-2 shadow-0 border-0 bg-theme-dark noisy">
          <div className="card-body p-0 bg-theme-dark noisy ps-2">
            {renderCourseList()}
          </div>
        </div>
      </div>
    </span>
  );
};

const CourseListItem = ({
  course,
  currentLesson,
  learnerDetails,
  setCurrentLesson,
  setViewedObject,
  setCurrentCourseRequirements,
  currentCourseRequirements,
  ...props
}) => {
  let renderCourseLessonList = () => {
    return course.lessons.map((lesson) => {
      return (
        <LessonItem
          key={lesson.id}
          lesson={lesson}
          setViewedObject={setViewedObject}
          currentLesson={currentLesson}
          setCurrentLesson={setCurrentLesson}
          setCurrentCourseRequirements={setCurrentCourseRequirements}
        />
      );
    });
  };
  return (
    <Fragment>
      <div
        className="card shadow-sm card-body btn bg-theme-dark-overlay text-light  p-2 mb-1 "
        data-bs-toggle="collapse"
        data-bs-target={`#collapse_coures_${course.id}`}
        aria-expanded="false"
      >
        <h5 className="card-title text-start fs-7">{course.title}</h5>
      </div>
      <div className="collapse p-2" id={`collapse_coures_${course.id}`}>
        <ul className="list-group">
          <RequirementListItem
            course={course}
            setCurrentLesson={setCurrentLesson}
            currentCourseRequirements={currentCourseRequirements}
            setCurrentCourseRequirements={setCurrentCourseRequirements}
          />
          {renderCourseLessonList()}
          <QuizzesListItem
            course={course}
            setCurrentLesson={setCurrentLesson}
            currentCourseRequirements={currentCourseRequirements}
            setCurrentCourseRequirements={setCurrentCourseRequirements}
          />
          <LiveSessionItems course={course} learnerDetails={learnerDetails} />
        </ul>
      </div>
    </Fragment>
  );
};

const LessonItem = ({
  lesson,
  currentLesson,
  setCurrentCourseRequirements,
  setCurrentLesson,
  setViewedObject,
  ...props
}) => {
  const [loadingViewState, setLoadingViewState] = useState(false);
  const [token] = useStateWithLocalStorage("learner_token");

  let handleViewChecked = (event) => {
    if (loadingViewState) {
      return;
    }

    updateLessonView(event.target.checked, event.target);
    setLoadingViewState(true);
  };

  let handleLessonSelection = () => {
    setCurrentCourseRequirements(null);
    setCurrentLesson(lesson);
  };

  const updateLessonView = async (flag, target) => {
    await learnerViewLesson(token, lesson.id, flag)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json_resp) => {
        // console.log(json_resp);
        if (json_resp && json_resp.viewed === "1") {
          target.checked = true;
        }
        setLoadingViewState(false);
        setViewedObject({
          ...json_resp,
          viewed: parseInt(json_resp.viewed),
          course_id: lesson.course_id,
        });
      })
      .catch((e) => {
        console.log(e);
        setLoadingViewState(false);
      });
  };
  return (
    <li
      className={`list-group-item  text-light p-1 ${
        currentLesson && currentLesson.id === lesson.id
          ? "bg-primary text-light"
          : "bg-theme-dark-overlay"
      }`}
    >
      <input
        className="form-check-input me-1 float-end"
        type="checkbox"
        checked={lesson.view && lesson.view.viewed === 1 ? true : false}
        onChange={handleViewChecked}
        aria-label="..."
      />
      <span
        className={`spinner-grow spinner-grow-sm me-2 text-light ${
          loadingViewState ? "" : "d-none"
        }`}
        role="status"
        aria-hidden="true"
      ></span>
      <span
        onClick={() => handleLessonSelection()}
        className="fs-7"
        style={{ cursor: "pointer" }}
      >
        {lesson.title}
      </span>
    </li>
  );
};

const RequirementListItem = ({
  setCurrentCourseRequirements,
  setCurrentLesson,
  course,
  currentCourseRequirements,
  ...props
}) => {
  let handleRequirmentClick = () => {
    setCurrentCourseRequirements(course);
    setCurrentLesson(null);
  };

  let listClassName = `list-group-item text-light p-1 ${
    currentCourseRequirements &&
    !currentCourseRequirements.show_quiz &&
    currentCourseRequirements.id === course.id
      ? "bg-primary text-light"
      : "bg-theme-dark-overlay"
  }`;
  return (
    <li className={listClassName} onClick={() => handleRequirmentClick()}>
      {/* <li className={`list-group-item`} onClick={()=>{setCurrentCourseRequirements(course);setCurrentLesson(null);}}> */}
      <FontAwesomeIcon className="text-info float-end" icon="book" />
      <i className="fs-6" style={{ cursor: "pointer" }}>
        Introduction
      </i>
    </li>
  );
};

const RequirementAndOutcomePanel = ({
  currentCourseRequirements,
  ...props
}) => {
  let getAllRequirements = () => {
    return currentCourseRequirements.requirements.map((requirement) => {
      return (
        <li key={requirement.id} className="list-group-item">
          {requirement.title}
        </li>
      );
    });
  };
  let getAllOutcomes = () => {
    return currentCourseRequirements.outcomes.map((outcome) => {
      return (
        <li key={outcome.id} className="list-group-item">
          {outcome.title}
        </li>
      );
    });
  };
  return (
    <div className="col-md-6 middle-panel flex-grow-1">
      <div className="card shadow-sm align-items-center justify-content-center">
        <h4 className="w-100 text-center mt-3">
          {currentCourseRequirements.title}
        </h4>
        <p className="text0-muted-w-100 ">
          <small>{currentCourseRequirements.desc}</small>
        </p>

        <div className="w-100 row">
          <div className=" p-4 flex-grow-1 align-items-center justify-content-center d-flex flex-column col-6">
            <h4 className="w-100 text-center ">Objectives</h4>
            <ul className="list-group list-group-flush">
              {getAllRequirements()}
            </ul>
          </div>

          <div className=" p-4 flex-grow-1 align-items-center justify-content-center d-flex flex-column col-6">
            <h4 className="w-100 text-center ">Outcomes</h4>
            <ul className="list-group list-group-flush">{getAllOutcomes()}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuizzesListItem = ({
  setCurrentCourseRequirements,
  setCurrentLesson,
  course,
  currentCourseRequirements,
  ...props
}) => {
  let handleRequirmentClick = () => {
    setCurrentCourseRequirements({ ...course, show_quiz: true });
    setCurrentLesson(null);
  };
  let viewCount = 0;
  course.lessons.forEach((lesson) => {
    if (lesson.view && lesson.view.viewed === 1) viewCount++;
  });
  let finishLesson = viewCount === course.lessons.length;
  let listClassName = `list-group-item text-light p-1 ${
    currentCourseRequirements &&
    currentCourseRequirements.show_quiz &&
    currentCourseRequirements.id === course.id
      ? "bg-primary text-light"
      : "bg-theme-dark-overlay"
  }`;
  return (
    <li className={listClassName} onClick={() => handleRequirmentClick()}>
      {!finishLesson ? (
        <FontAwesomeIcon className="text-light ms-2 float-end" icon="lock" />
      ) : (
        <Fragment />
      )}

      <FontAwesomeIcon className="text-success float-end" icon="pen-alt" />

      <i className="fs-6" style={{ cursor: "pointer" }}>
        Quizzes
      </i>
    </li>
  );
};

const LiveSessionItems = ({ course, learnerDetails, ...props }) => {
  let filterAps = learnerDetails.organization.active_live_sessions.filter(
    (session) => {
      return session.lesson.course_id === course.id;
    }
  );
  let listClassName = `list-group-item text-light p-1 `;
  let renderAllLiveSession = filterAps.map((session) => {
    return (
      <li className={listClassName} key={session.id}>
        <Link
          to={`live-session/${session.id}`}
          className="fs-8 text-dark"
          style={{ cursor: "pointer" }}
        >
          <FontAwesomeIcon className="text-info float-end" icon="link" />
          Live Session <Moment fromNow>{session.session_date}</Moment>
        </Link>
        {session.external_link ? (
          <a
            href={session.external_link}
            target="_blank"
            className="btn btn-success p-0 m-0 fs-8"
            rel="noreferrer"
          >
            external link
          </a>
        ) : (
          ""
        )}
      </li>
    );
  });

  return <Fragment>{renderAllLiveSession}</Fragment>;
};
export { CoursePanel, RequirementAndOutcomePanel };
