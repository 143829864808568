import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useStateWithLocalStorage } from "../../hooks/token_hooks";
import { getLiveSessionTokenCall } from "../../network/learner/learner_session_manager";
import Room from "./room";

const LiveSessionVideo = ({live_session, setShowLiveSession, ...props}) => {
    // const [room, setRoomId] = useState(null);
    const [access_token, setToken] = useState(null);
    const [token] = useStateWithLocalStorage("learner_token");

    const [fullScreen, setFullScreen] = useState(false);
    
    useEffect(() => {
        const getLearnerCourses = async ()=>{
            await getLiveSessionTokenCall(token, live_session)
            .then((response)=>{
                if(response.ok){
                    return response.json();
                }
                setToken('no live session');
                // setRoomId(null);
            })
            .then((json_resp)=>{
                if(json_resp){
                    setToken(json_resp.token);
                    // setRoomId(json_resp.room_name);
                }
                
                // console.log('json_resp', json_resp);
                
                
            }).catch((e)=>{
                console.log(e)
                
            })
        }
        getLearnerCourses();
    }, [live_session, token]);

    
    let renderContent = ()=>{
        if(access_token === 'no live session') return <h4>Session is not Live</h4>
        if(!access_token) return <h4>
                            loading...
                            <span className="spinner-grow spinner-grow-sm ms-2 text-success" role="status" aria-hidden="true"></span>
                        </h4>
        return  <Room
                token={access_token}
                setShowLiveSession={setShowLiveSession}
                />
    }
    return ( 
        <div className={`col-sm-12 shadow border-0 card mt-1 position-fixed live_seesion_container ${fullScreen?'live_seesion_container-fullscreen':''}`}>
            <div className="card-body h-100  p-1 d-flex flex-column align-items-top justify-content-center">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <div className="position-absolute top-0 end-0" style={{zIndex:'10000'}}>
                    <FontAwesomeIcon onClick={()=>setFullScreen(old=>!old)} icon={fullScreen?"compress":"expand"} size="2x" className="mx-2" />
                    <button className="btn btn-danger m-2" onClick={()=>setShowLiveSession(false)}>Leave Session</button>
                </div>
                
                {renderContent()}
                    
                </div>
                
            </div>
            
        </div>
     );
}
 
export default LiveSessionVideo;