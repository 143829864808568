import { host_url } from "../utils"

const learnerLoginCall = (data) => {
    let headerList = {
        // "Authorization": token,
        "accept": "application/json",
      }
    return fetch(`${host_url}/learner/login`, {
        method:'POST',
        headers: headerList,
        body: data
    })
}

const learnerSendOTPCall = (data) => {
    let headerList = {
        // "Authorization": token,
        "accept": "application/json",
      }
    return fetch(`${host_url}/learner/reset-password`, {
        method:'POST',
        headers: headerList,
        body: data
    })
}

const learnerUpdateOTPCall = (data) => {
    let headerList = {
        // "Authorization": token,
        "accept": "application/json",
      }
    return fetch(`${host_url}/learner/update-password`, {
        method:'POST',
        headers: headerList,
        body: data
    })
}


export {learnerLoginCall, learnerSendOTPCall, learnerUpdateOTPCall}