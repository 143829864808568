import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";
import Content from "./content";
import LearnerDashboard from "./dashboard/learner_dashboard";
import Help from "./help/help";
import LiveSessionPage from "./live-session/landing";
import LoginPage from "./login";
import Notification from "./notification/notification";
import ProtectedRoute from "./protect_route";
import QuizPage from "./quiz/landing";
import ProtectedQuizRoute from "./quiz/validated_quiz_route";
  
  // This site has 3 pages, all of which are rendered
  // dynamically in the browser (not server rendered).
  //
  // Although the page does not ever refresh, notice how
  // React Router keeps the URL up to date as you navigate
  // through the site. This preserves the browser history,
  // making sure things like the back button and bookmarks
  // work properly.
  
export default function MainRouteApp() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/about">
              <Content />
            </Route>
            <Route path="/help">
              <Help />
            </Route>
            <Route path="/notification">
              <Notification />
            </Route>
            <Route path="/learner-dashboard-tmp">
            <LearnerDashboard />
            </Route>
            <ProtectedRoute path="/learner-dashboard">
              <LearnerDashboard />
            </ProtectedRoute>
            <ProtectedQuizRoute path="/learner-quiz/:id">
              <QuizPage />
            </ProtectedQuizRoute>
            <ProtectedRoute path="/live-session/:id">
              <LiveSessionPage />
            </ProtectedRoute>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/">
              <LoginPage />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }