import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import LeftList from "./leftside_panel";
import MessagePanel from "./middle_panel";
const HeaderPanel = ({currentSession,setShowSessions, showSessions, setShowLiveSession,showLiveSession, ...props}) => {
    let handleSessionEntry = (event)=>{
        setShowLiveSession(old_id=>!old_id);
    }
    return ( 
        <header className="d-flex w-100 flex-row flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-md-4 noisy-dashboard">
            <Link to="/learner-dashboard" className="d-md-flex d-inline align-items-center justify-content-md-left justify-content-center
                col-md-3 mb-2 mb-md-0 col-4 text-dark text-decoration-none">
            <img src="/logo.png" className="w-25" alt="logo" />
            </Link>

            
            <div className="flex-md-grow-1 text-start text-md-center d-md-block d-inline ">
                <h4 className="text-bold  m-0 engraved">{currentSession?currentSession.lesson.title:'Lesson not Available'}</h4>
                <h6 className="text-bold  m-0 engraved"><span className="text-dark">Instructor: </span>{currentSession?currentSession.instructor.name:''}</h6>
                
            </div>
            <div className="mt-3 mt-md-0 d-md-flex flex-grow-1 d-inline flex-wrap align-items-center justify-content-center col-md-3 col-sm-12 px-2">
                <button className="btn btn-primary d-md-none text-light" onClick={()=>setShowSessions(!showSessions)}>
                    <FontAwesomeIcon className="text-light" icon={["fab", "readme"]} />
                </button>
                {!showLiveSession?<button className="btn btn-primary text-light float-end mx-2" onClick={handleSessionEntry}> 
                    Enter Session
                    <FontAwesomeIcon className="text-danger ms-2 " icon="video" />
                </button>:''}
                {currentSession?currentSession.external_link?<a href={currentSession.external_link} target="_blank" className="btn btn-success" rel="noreferrer">external link</a>:"none":"no live session found"}
                <button className="btn btn-transparent text-primary float-end"> <small>Session time: </small> <Moment fromNow>{currentSession?currentSession.session_date:''}</Moment></button>
                    
            </div>
            
            
        </header>
     );
}

const Main = ({learnerDetails, currentSession, showSessions, showLiveSession, ...props}) => {
    return ( 
        <div className="row w-100 flex-grow-1">
            <div className={`col-lg-3 col-md-4 ps-md-3 pt-1 h-100 dashboard-left-panel ${showSessions?'m-0':''}`}>
                <LeftList
                    learnerDetails={learnerDetails}
                    currentSession={currentSession}
                    
                    />
            </div>
            <MessagePanel
                learnerDetails={learnerDetails}
                currentSession={currentSession}
                showLiveSession={showLiveSession}
                
            />
            
        </div>
     );
}

export {HeaderPanel, Main}