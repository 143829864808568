import { host_url } from "../utils"

const learnerDetailsCall = (token) => {
    let headerList = {
        "Authorization": `Bearer ${token}`,
        "accept": "application/json",
      }
    return fetch(`${host_url}/learner/details`, {
        method:'GET',
        headers: headerList,
    })
}

const learnerCoursesCall = (token) => {
    let headerList = {
        "Authorization": `Bearer ${token}`,
        "accept": "application/json",
      }
    return fetch(`${host_url}/learner/courses`, {
        method:'GET',
        headers: headerList,
    })
}

const learnerViewLesson = (token, lesson_id, flag) => {
    if(flag){
        flag=1
    }else{
        flag=0
    }
    let headerList = {
        "Authorization": `Bearer ${token}`,
        "accept": "application/json",
      }
    return fetch(`${host_url}/learner/lesson/${lesson_id}/update-lesson-view/${flag}`, {
        method:'GET',
        headers: headerList,
    })
}

const learnerUpdateCall = (token, data) => {
    let headerList = {
        "Authorization": `Bearer ${token}`,
        "accept": "application/json",
      }
    return fetch(`${host_url}/learner/update`, {
        method:'POST',
        headers: headerList,
        body: data
    })
}


const learnerLogoutCall = (token) => {
    let headerList = {
        "Authorization": `Bearer ${token}`,
        "accept": "application/json",
      }
    return fetch(`${host_url}/learner/logout`, {
        method:'GET',
        headers: headerList,
    })
}


export {learnerDetailsCall, learnerCoursesCall, learnerUpdateCall, learnerViewLesson, learnerLogoutCall};