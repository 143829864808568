import { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useStateWithLocalStorage } from "../../hooks/token_hooks";
import { learnerCoursesCall } from "../../network/learner/learner_manager";
import "react-calendar/dist/Calendar.css";
import { Header, Main } from "./main_comp";
import Profile from "../profile/landing";

const LearnerDashboard = ({ learnerDetails, setLearnerDetails, ...props }) => {
  const [courses, setCourses] = useState([]);
  const [token] = useStateWithLocalStorage("learner_token");
  const [loadingCourseState, setLoadingCourseState] = useState(true);
  const [showCourseList, setShowCourseList] = useState(false);
  const [showCourseProgress, setShowCourseProgress] = useState(false);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [currentCourseRequirements, setCurrentCourseRequirements] =
    useState(null);
  const [viewedObject, setViewedObject] = useState(null);

  const [readCourseText, setReadCourseText] = useState(false);

  let { path } = useRouteMatch();

  useEffect(() => {
    const getLearnerCourses = async () => {
      await learnerCoursesCall(token)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((json_resp) => {
          setCourses(json_resp);
          setLoadingCourseState(false);
        })
        .catch((e) => {
          console.log(e);
          setLoadingCourseState(false);
        });
    };
    getLearnerCourses();
  }, [token]);

  useEffect(() => {
    let updateLessonViewed = () => {
      if (!viewedObject) return;
      let tmpCourses = [...courses];
      let newCoursesList = tmpCourses.map((course) => {
        if (course.id === viewedObject.course_id) {
          var tmpLessons = [...course.lessons];
          var newLessonList = tmpLessons.map((lesson) => {
            if (lesson.id === viewedObject.lesson_id) {
              return { ...lesson, view: viewedObject };
            }
            return lesson;
          });
          return { ...course, lessons: newLessonList };
        }
        return course;
      });
      setViewedObject(null);
      setCourses(newCoursesList);
    };
    updateLessonViewed();
  }, [courses, viewedObject]);

  return (
    <div className="container-fluid d-flex flex-column vh-100">
      <Header
        learnerDetails={learnerDetails}
        currentLesson={currentLesson}
        setShowCourseList={setShowCourseList}
        showCourseList={showCourseList}
        setShowCourseProgress={setShowCourseProgress}
        showCourseProgress={showCourseProgress}
      />
      <Switch>
        <Route exact path={path}>
          <Main
            setViewedObject={setViewedObject}
            courses={courses}
            isCourseloading={loadingCourseState}
            learnerDetails={learnerDetails}
            setShowCourseList={setShowCourseList}
            setCurrentLesson={setCurrentLesson}
            currentLesson={currentLesson}
            showCourseList={showCourseList}
            showCourseProgress={showCourseProgress}
            setShowCourseProgress={setShowCourseProgress}
            currentCourseRequirements={currentCourseRequirements}
            setCurrentCourseRequirements={setCurrentCourseRequirements}
            readCourseText={readCourseText}
            setReadCourseText={setReadCourseText}
          />
        </Route>
        <Route path={`${path}/profile`}>
          <Profile
            learnerDetails={learnerDetails}
            setLearnerDetails={setLearnerDetails}
          />
        </Route>
      </Switch>
    </div>
  );
};
export default LearnerDashboard;
