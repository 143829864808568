import { Fragment, useEffect, useState } from "react";

const PopUp = ({message, type, ...props}) => {
    const [localMessage, setlocalMessage] = useState();
    useEffect(() => {
        setlocalMessage(message)
    }, [message]);
    if (!localMessage){
        return (<Fragment></Fragment>)
    }
    return ( 
        <div className={`alert alert-${localMessage.type??'warning'} alert-dismissible fade show`} role="alert">
            <strong>Hey!</strong> {localMessage.message}
            <button type="button" className="btn-close" onClick={()=>setlocalMessage(null)}  aria-label="Close"></button>
        </div>
     );
}
 
export { PopUp};