import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, createRef, Fragment } from "react";
import { Link } from "react-router-dom";
import { useStateWithLocalStorage } from "../../hooks/token_hooks";
import { learnerViewLesson } from "../../network/learner/learner_manager";
import { base_url } from "../../network/utils";
import AttachmentPreview from "./AttachmentPreview";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ContentPanel = ({
  lesson,
  setCurrentLesson,
  courses,
  readCourseText,
  setViewedObject,
  setCurrentCourseRequirements,
  setAttachmentToPreview,
  attachmentToPreview,
}) => {
  const [token] = useStateWithLocalStorage("learner_token");

  const [readPercent, setReadPercent] = useState(0);
  const [nextLesson, setNextLesson] = useState(null);
  const [previousLesson, setPreviousLesson] = useState(null);
  const [paused, setPaused] = useState(false);

  const textContainer = createRef();
  const synthesis = window.speechSynthesis;

  var createMarkup = () => {
    return { __html: lesson.content };
  };

  useEffect(() => {
    setReadPercent(0);
    setNextLesson(null);
    setPreviousLesson(null);
    courses.forEach((course) => {
      if (lesson.course_id === course.id) {
        course.lessons.forEach((m_lesson, index) => {
          if (lesson.id === m_lesson.id) {
            if (course.lessons[index + 1]) {
              setNextLesson(course.lessons[index + 1]);
            }
            if (index - 1 >= 0) {
              setPreviousLesson(course.lessons[index - 1]);
            }
          }
        });
      }
    });
    synthesis.cancel();
    if (readCourseText && textContainer.current) {
      let readOut = (m_text) => {
        if (synthesis) {
          let splitedText = [];
          while (splitedText.length * 32767 < m_text.length) {
            splitedText.push(
              m_text.slice(
                splitedText.length * 32767,
                (splitedText.length + 1) * 32767
              )
            );
          }

          // Get the first `en` language voice in the list
          var voice = synthesis.getVoices().filter(function (voice) {
            return voice.lang === "en";
          })[0];

          // Create an utterance object

          // Speak the utterance
          splitedText.forEach((text_chuck) => {
            let utterance = new SpeechSynthesisUtterance();

            // Set utterance properties
            utterance.voice = voice;
            utterance.pitch = 1.2;
            utterance.rate = 0.8;
            utterance.volume = 0.8;
            utterance.text = text_chuck;
            synthesis.speak(utterance);
          });
        } else {
          console.log("Text-to-speech not supported.");
        }
      };
      readOut(textContainer.current.innerText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses, lesson, readCourseText, synthesis]);

  let handleScroll = (event) => {
    event.preventDefault();
    let target = event.target;
    setReadPercent(
      (target.scrollTop / (target.scrollHeight - target.clientHeight)) * 100
    );
  };

  const updateLessonView = async (flag) => {
    await learnerViewLesson(token, lesson.id, flag)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json_resp) => {
        setViewedObject({
          ...json_resp,
          viewed: parseInt(json_resp.viewed),
          course_id: lesson.course_id,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  let handleNextClick = (event) => {
    updateLessonView(1);
    if (nextLesson) {
      setCurrentLesson(nextLesson);
    }
  };

  let handleFinishClick = (event) => {
    updateLessonView(1);
    synthesis.cancel();
    let currentCourse = courses.find((m_course) => {
      return m_course.id === lesson.course_id;
    });
    setCurrentLesson(null);
    setCurrentCourseRequirements({ ...currentCourse, show_quiz: true });
  };
  let handlePreviousClick = (event) => {
    if (previousLesson) {
      setCurrentLesson(previousLesson);
    }
  };

  let renderPlayButton = () => {
    if (!readCourseText) return <Fragment />;
    return (
      <div
        className="position-fixed btn"
        style={{ right: "20%", top: "20%", opacity: ".5" }}
      >
        {paused ? (
          <FontAwesomeIcon
            onClick={() => {
              synthesis.resume();
              setPaused(false);
            }}
            className="text-dark mx-2"
            size="3x"
            icon="play-circle"
          />
        ) : (
          <FontAwesomeIcon
            onClick={() => {
              synthesis.pause();
              setPaused(true);
            }}
            className="text-dark  mx-2"
            size="3x"
            icon="pause-circle"
          />
        )}
      </div>
    );
  };

  return (
    <div
      className="col-lg-8 col-md-7 middle-panel flex-grow-1"
      {...(!lesson.pdf && !attachmentToPreview
        ? { onScroll: handleScroll }
        : {})}
    >
      {renderPlayButton()}
      <h4 className="w-100 text-center mt-3 fs-4">{lesson.title}</h4>
      <div className="shadow-sm align-items-center justify-content-center p-3">
        {attachmentToPreview ? (
          <AttachmentPreview
            attachment={attachmentToPreview}
            handleClose={() => setAttachmentToPreview(null)}
          />
        ) : (
          <Fragment>
            {lesson.pdf ? (
              <div className="p-relative pdf_container">
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={[{ uri: `${base_url}api/download/${lesson.pdf}` }]}
                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: true,
                    },
                  }}
                />
              </div>
            ) : (
              <div
                className="w-100 content-displayer"
                ref={textContainer}
                dangerouslySetInnerHTML={createMarkup()}
              />
            )}
          </Fragment>
        )}
      </div>

      <div className="w-100 p-2">
        {previousLesson && (
          <button
            className="btn btn-secondary text-light"
            onClick={handlePreviousClick}
          >
            Previous
          </button>
        )}
        {nextLesson ? (
          <button
            className="btn btn-primary text-light float-end"
            onClick={handleNextClick}
          >
            Next
          </button>
        ) : (
          <button
            className="btn btn-primary text-light float-end"
            onClick={handleFinishClick}
          >
            Finish
          </button>
        )}
      </div>

      <div className="position-fixed bottom-0 start-0 w-100">
        <div className="progress" style={{ height: "8px" }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${readPercent}%` }}
            aria-valuenow={readPercent}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
  );
};

const IntroPanel = ({ learnerDetails, ...props }) => {
  return (
    <div className="col-md-6 m-sm-0 p-0 mt-1 flex-grow-1">
      <div className="card border-0 align-items-center justify-content-center">
        <div className="col-sm-12 col-md-10 offset-md-1 d-flex flex-column mt-3">
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="3000"
            data-bs-pause="false"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="/coffee_break.png"
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption d-none d-md-block text-primary">
                  {/* <h5>First slide label</h5>
                                    <p>Some representative placeholder content for the third slide.</p> */}
                </div>
              </div>
              <div className="carousel-item">
                <img
                  src="/online_learning.png"
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption d-none d-md-block text-primary">
                  {/* <h5>Second slide label</h5>
                                    <p>Some representative placeholder content for the third slide.</p> */}
                </div>
              </div>
              <div className="carousel-item">
                <img src="/quiz.png" className="d-block w-100" alt="..." />
                <div className="carousel-caption d-none d-md-block text-primary">
                  {/* <h5>Third slide label</h5>
                                    <p>Some representative placeholder content for the third slide.</p> */}
                </div>
              </div>
              <div className="carousel-item">
                <img
                  src="/group_video.png"
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption d-none d-md-block text-primary">
                  {/* <h5>Fourth slide label</h5>
                                    <p>Some representative placeholder content for the third slide.</p> */}
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
      <div className="col-12 d-md-none">
        <img src="/board.jpeg" className="d-block w-100" alt="..." />
      </div>
    </div>
  );
};

const QuizzesPanel = ({ courses, currentCourseQuizzes, ...props }) => {
  const [viewCount, setViewCount] = useState(0);
  let currentCourse = courses.find((m_course) => {
    return m_course.id === currentCourseQuizzes.id;
  });

  let finishLesson = viewCount === currentCourse.lessons.length;
  useEffect(() => {
    let tmpViewCount = 0;
    currentCourse.lessons.forEach((lesson) => {
      if (lesson.view && lesson.view.viewed === 1) tmpViewCount++;
    });
    setViewCount(tmpViewCount);
    // console.log('currentCourseQuizzes', currentCourseQuizzes)
  }, [courses, currentCourse.lessons, currentCourseQuizzes]);
  let getAllQuiz = () => {
    if (!finishLesson)
      return (
        <div className="text-center text-muted">
          <FontAwesomeIcon
            className="text-primary mx-2"
            size="3x"
            icon="lock"
          />
          <h6 className="d-inline-block">
            Lessons have to be completed before access quizzes
          </h6>
        </div>
      );
    if (
      currentCourse &&
      currentCourseQuizzes &&
      currentCourse.quizzes.length === 0
    )
      return <h6 className="text-muted text-center">No Quiz Available</h6>;
    return currentCourse.quizzes.map((quiz) => {
      let inits = quiz.title.toUpperCase().split(" ");
      return (
        <div key={quiz.id} className="col-sm-6 p-1">
          <div className="card mb-3 ">
            <div className="row g-0">
              <div className="col-md-4 d-flex text-center justify-content-center flex-column gradient-text">
                <span style={{ fontSize: "4em" }}>
                  {inits[0] ? inits[0][0] ?? "" : ""}
                  {inits[1] ? inits[1][0] ?? "" : ""}
                </span>
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">{quiz.title}</h5>
                  <p className="card-text">
                    Duration{" "}
                    <small className="text-muted">
                      {quiz.duration} minutes
                    </small>
                  </p>
                  <Link
                    to={`learner-quiz/${quiz.id}`}
                    className="btn btn-primary text-light"
                  >
                    Take Quiz
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="col-md-6 middle-panel flex-grow-1">
      <div className="card shadow-sm align-items-center justify-content-center">
        <h4 className="w-100 text-center mt-3">
          {currentCourseQuizzes.title} Quizzes
        </h4>

        <div className="w-100 p-2 row">{getAllQuiz()}</div>
      </div>
    </div>
  );
};

export { ContentPanel, IntroPanel, QuizzesPanel };
