import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { useStateWithLocalStorage } from "../../hooks/token_hooks";
import { submitQuizSessionCall } from "../../network/learner/learner_quiz_manager";


const QuizIntroPanel = ({quizDetails, setViewState, 
    setCurrentQuestion, allQuestions, setCurrentQuestionResult, ...props}) => {
    let inits = quizDetails.title.toUpperCase().split(' ');
    const [allSessions, setAllSessions] = useState([]);
    let beginQuiz = ()=>{
        if(!allQuestions[0]) return;
        setCurrentQuestion(allQuestions[0])
        setViewState(1)
    }

    let getQuizSessions = ()=>{
        if(!quizDetails.quiz_session) return<Fragment/>;
        return allSessions.map((quiz_session)=>{
            return  <li key={quiz_session.id} className="list-group-item">
                        <span>
                            <Moment fromNow>{quiz_session.created_at}</Moment>
                        </span>
                        <button onClick={()=>{
                            setCurrentQuestionResult(quiz_session)
                            setViewState(3);
                            
                            }} className="btn btn-primary float-end  text-light ">View Quiz Result</button>
                    </li>;
        })
    }

    useEffect(() => {
        setAllSessions(quizDetails.quiz_session.reverse());
    }, [quizDetails.quiz_session]);
    return ( 
        <div className="col-md-12 flex-grow-1">
            <div className="card shadow-sm align-items-center justify-content-center">
                <h4 className="w-100 text-center mt-3">Self Assesment Quiz</h4>
                <h4 className="w-100 text-center fs-6">{quizDetails.title}</h4>
                <div className="w-100 d-flex text-center justify-content-center flex-column p-3" style={{width:'50px',}}>
                    <div className="w-100 text-center gradient-text">
                        <div className="border border-1 shadow rounded d-inline-block overflow-hidden" style={{width:'150px',}}>
                        <span style={{fontSize:'4em'}}>{inits[0]?inits[0][0]??'':''}{inits[1]?inits[1][0]??'':''}</span>
                        </div>
                    </div>
                    <h6 className="w-100 text-muted "><i>Total Questions</i> : {quizDetails.questions.length}</h6>
                    <h6 className="w-100 text-muted "><i>Duration</i> : {quizDetails.duration} minutes</h6>
                    <h6 className="w-100 text-muted "><i>Total Score</i> : {quizDetails.total_score??'Not Available'}</h6>
                    
                </div>
                <button onClick={()=>beginQuiz()} className="btn btn-primary mb-3  text-light mt-5 ">Begin Quiz</button>
                <h4 className="w-100 text-center mt-3">Previous Assesments</h4>
                <ul className="list-group w-100 p-2">
                    {getQuizSessions()}
                    
                </ul>
                
            </div>
            
            
            
        </div>
        
     );
}

const NumberBox = ({allQuestions, setCurrentQuestion, currentQuestion, ...props}) => {
    
    let bgColorClass = (question)=>{
        if (currentQuestion.id===question.id) return 'bg-info border-info';
        if ( 
            (question.selectedOptions && question.selectedOptions.length > 0)||
            (question.selectedShortAnswer && question.selectedShortAnswer.length>0)
            ) return 'bg-navy border-success';
        return 'bg-primary';
    }

    let getNumber = ()=>{
        return allQuestions.map((question)=>{
            let boxStyle = `p-1  text-light
                            ms-1 mb-2 btn shadow border 
                             border-1 
                            ${bgColorClass(question)}
                            `;
            return  <span   key={question.id} 
                            onClick={()=>{setCurrentQuestion(question)}} 
                            className={boxStyle} style={{width:'40px'}}>
                        {question.number}
                    </span>;
        })
        
    }

    return ( 
        <div className="d-flex flex-wrap align-items-center justify-content-center ">
            {getNumber()}
        </div>
     );
}

const OptionList = ({currentQuestion, setAllQuestions,allQuestions, ...props}) => {

    let selecteOptionId = currentQuestion.selectedOptions?? [];   

    let handleSelection = (event)=>{
        let optionId = parseInt(event.target.name);
        let newSelectedOptionId = [];
        if(selecteOptionId.indexOf(optionId) !== -1){
            newSelectedOptionId = selecteOptionId.filter((option)=>{
                return option !== optionId;
            });
        }else{
            newSelectedOptionId = [optionId];
        }

        let newAllQuestions = allQuestions.map((question)=>{
            if (question.id === currentQuestion.id) return {...question, selectedOptions: newSelectedOptionId }
            return question;
        });

        setAllQuestions(newAllQuestions);

    }
 
    let renderOptions = ()=>{
        return currentQuestion.options.map((option)=>{
            return  <li key={option.id} className="list-group-item border-0">
                        <input name={option.id} onChange={handleSelection} className="form-check-input me-2" checked={selecteOptionId.indexOf(option.id)!==-1} type="checkbox" value="" aria-label="..."/>
                        {option.quiz_option_title}
                    </li>;
        })
        
    }

    return ( 
        <div className="col-12 mt-2">
            <ul className="list-group list-group-flush">
                {renderOptions()}
            </ul>
        </div>
     );
}

const TimerCountDown = ({quizDetails, allQuestions, setSubmissionData,setViewState, ...props}) => {
    const [endDate] = useState(new Date( (new Date()).getTime() +(quizDetails.duration*60000) ));
    
    let handleSubmit = ()=>{
        
        let submissonObject = allQuestions.map((question)=>{
            return {id:question.id, selectedOptions:question.selectedOptions, selectedShortAnswer:question.selectedShortAnswer}
        })
        setSubmissionData(submissonObject);
        setViewState(2)
    }
    const calculateTimeLeft = () => {
        let difference = endDate - +new Date();
        let timeLeft = {};

        
    
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }else{
            handleSubmit();
        }
    
      return timeLeft;
    
    }
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        const timer=setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
      });

    return ( 
        <div className="flex-grow-1 h4 text-center text-muted">
            <span className="me-2">Time Left: </span>
            <span className={`${timeLeft.minutes < 3 ?'text-danger _blinker': timeLeft.minutes < 10 ?'text-warning':'text-dark' }`}>
                {timeLeft.seconds || timeLeft.minutes ? `${(''+timeLeft.minutes).padStart(2, '0')} : ${(''+timeLeft.seconds).padStart(2, '0')}` : <span>Time's up!</span>}
            </span>
        </div> 
     );
}

const QuizSubmittedPanel = ({quizDetails, setViewState,submissionData, ...props}) => {
    const [token] = useStateWithLocalStorage("learner_token");
    const [loadingSubmit, setLoadingSubmit] = useState(true);
    const [stateText, setStateText] = useState("submitting...");
    let inits = quizDetails.title.toUpperCase().split(' ');
    useEffect(() => {

        if(!submissionData)return;
        const submitQuizSession = async (data)=>{
            await submitQuizSessionCall(token, quizDetails.id, data)
            .then((response)=>{
                // console.log(response.status)
                if(response.ok){
                    setLoadingSubmit(false);
                    setStateText('Quiz has been Submitted');
                    return response.json();
                }
                
                
            })
            .then((json_resp)=>{
                // console.log(json_resp)
                
            }).catch((e)=>{
                // console.log(e)
                setLoadingSubmit(false);
                setStateText('failed to submit')
                
                
            })
        }
        submitQuizSession(submissionData);
    }, [submissionData, quizDetails.id, token]);

    
    return ( 
        <div className="col-md-12 flex-grow-1">
            <div className="card shadow-sm align-items-center justify-content-center">
                <h4 className="w-100 text-center fs-6">{quizDetails.title}</h4>
                <div className="w-100 d-flex text-center justify-content-center flex-column p-3" style={{width:'50px',}}>
                    <div className="w-100 text-center">
                        <div className="border border-1 shadow rounded d-inline-block overflow-hidden" style={{width:'150px',}}>
                        <span style={{fontSize:'4em'}}>{inits[0]?inits[0][0]??'':''}{inits[1]?inits[1][0]??'':''}</span>
                        </div>
                    </div>
                    
                    <h6 className="w-100 text-muted ">{stateText}</h6>    
                </div>
                {
                    !loadingSubmit?
                    <Link to="/learner-dashboard">
                        <button className="btn btn-primary mb-3  text-light mt-5 ">
                            Return to Course
                        </button>
                    </Link>
                    :
                    <span className={`spinner-grow spinner-grow-sm m-2 text-primary`} role="status" aria-hidden="true"></span>
                }
                
                
                
                
            </div>
            
        </div>
        
     );
}
const QuizProgressPanel = ({allQuestions, ...props}) => {
    const [progress, setProgress] = useState(70);
    let options = {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "40%",
              },
              track: {
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  blur: 4,
                  opacity: 0.15
                }
              },
              dataLabels: {
                name: {
                  offsetY: 60,
                  color: "#000",
                  fontSize: "13px",
                  show: false
                },
                value: {
                    offsetY: 10,
                    color: "#000",
                    fontSize: "20px",
                    show: true
                }
              }
            }
        },
        fill: {
        type: "gradient",
        gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#87D4F9"],
            stops: [0, 100]
        }
        },
        stroke: {
        lineCap: "round"
        },
        
        labels: [' '],
    };
    let series = [progress];
    useEffect(() => {
        let selectedQuestions = 0;
        allQuestions.forEach((question)=>{
            if (
                (question.selectedOptions && question.selectedOptions.length > 0)||
                (question.selectedShortAnswer && question.selectedShortAnswer.length>0)
                    ) selectedQuestions++;
        })
        setProgress( Math.round( (selectedQuestions/allQuestions.length) * 100 ) )
    }, [allQuestions]);
    return ( 

        <div className="card pb-5 mt-3 me-lg-5 pt-2 shadow border-0">
            <div className="card-body p-1">
                <div className="row">
                    <div className="col-sm-6 d-flex align-items-center justify-content-center text-center ">
                        <small className="text-muted">you can submit anytime</small>
                    </div>
                    <ReactApexChart className="col-sm-6" options={options} series={series} type="radialBar" height={150} />
                </div>
                
            </div>
            
        </div>
        

     );
}

const ShortAnswerOption = ({currentQuestion, allQuestions, setAllQuestions, ...props}) => {
    const [optionValue, setOptionValue] = useState("");

    useEffect(() => {
        // if(!currentQuestion.selectedShortAnswer) return;
        setOptionValue(currentQuestion.selectedShortAnswer)
    }, [currentQuestion]);
    let handleNewInput = (event)=>{
        let newAllQuestions = allQuestions.map((question)=>{
            if (question.id === currentQuestion.id) return {...question, selectedShortAnswer: event.target.value }
            return question;
        });
        setAllQuestions(newAllQuestions);
    }
    return ( 
        <div className="col-sm-10 mt-3">
            <textarea onChange={handleNewInput} name="" id="" cols="30" value={optionValue} rows="10" className="form-control me-2"/>
        </div>
     );
}

const FooterPanel = ({currentQuestion,setCurrentQuestion,allQuestions, setViewState, setSubmissionData, ...props}) => {
    
    const [showModal, setShowModal] = useState(false);
    let previousQuestion =  null;
    let nextQuestion = null;
    allQuestions.forEach((question,index)=>{
        if(question.id  === currentQuestion.id){
            if(index-1 >= 0){
                previousQuestion = allQuestions[index-1];
            }
            if(index+1 < allQuestions.length){
                nextQuestion = allQuestions[index+1];
            }
        }
    })

    
    let handleSubmit = ()=>{
        setShowModal(false);
        
        let submissonObject = allQuestions.map((question)=>{
            return {id:question.id, selectedOptions:question.selectedOptions, selectedShortAnswer:question.selectedShortAnswer}
        })
        setSubmissionData(submissonObject);
        setViewState(2)
    }

    let confirmFinish = ()=>{
        setShowModal(true);
    }
    return (  
        <div className="col-12 ps-2">
            <div className="row mt-2">
                
                <div className="col-sm-4 ps-5">
                    <small>Question {currentQuestion.number} of {allQuestions.length}</small>
                </div>
                <div className="col-sm-8 text-md-end text-end pe-5">
                    <span className="btn btn-transparent text-primary"  onClick={()=>confirmFinish()}>finish</span>
                    <div className={`btn btn-primary text-light mx-2 ${!previousQuestion?'d-none':''}`} onClick={()=>setCurrentQuestion(previousQuestion)}>
                        <FontAwesomeIcon className="text-light" size="2x" icon="angle-left" />
                    </div>
                    <div className={`btn btn-primary text-light ${!nextQuestion?'d-none':''}`} onClick={()=>setCurrentQuestion(nextQuestion)}>
                        <FontAwesomeIcon className="text-light" size="2x" icon="angle-right" />
                    </div>
                    
                </div>
            </div>
            {showModal?<div className="modal-backdrop fade show"></div>:<Fragment/>}
            <div className={`modal fade ${showModal?'show':''}`} id="submitDialog" aria-hidden="true" style={{display:showModal?'block':'none',}}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="submitDialogLabel">Are you done?</h5>
                        <button type="button" className="btn btn-tranparent" onClick={()=>setShowModal(false)}>
                            <FontAwesomeIcon className="text-primary" size="2x" icon="times" />
                        </button>
                    </div>
                    <div className="modal-body">
                        Submissions can not be changed
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={()=>setShowModal(false)}>Continue Quiz</button>
                        <button type="button" className="btn btn-primary text-light" onClick={()=>handleSubmit()}>Submit</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 



 
export {QuizIntroPanel, NumberBox, OptionList, 
    TimerCountDown, QuizSubmittedPanel, QuizProgressPanel,
     ShortAnswerOption, FooterPanel}