import React from 'react';
import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core'

import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import MainRouteApp from './components/main_router';



import './styles/main.scss';
import 'bootstrap/dist/js/bootstrap.js';
import { faAngleLeft, faAngleRight, faBars, faBell, faBook, faCamera, faCheckCircle, faCheckSquare, faCoffee, faCommentAlt, faCompress, faEdit, faEllipsisV, faExpand, faEye, faEyeSlash, faFileAlt, faFileArchive, faFilePdf, faFileVideo, faHandPointLeft, faHome, faLink, faLock, faMicrophone, faMicrophoneSlash, faPauseCircle, faPenAlt, faPlayCircle, faPlusCircle, faQuestionCircle, faTimes, faTimesCircle, faUserShield, faVideo, faVideoSlash, faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { fab, faReadme } from '@fortawesome/free-brands-svg-icons';

library.add(fab, faCheckSquare, faCoffee, faFilePdf, 
        faFileAlt, faFileVideo, faLink, faBars, faLink,
          faPlusCircle, faTimes, faFileArchive, faUserShield,
          faBook, faPenAlt, faEdit, faCamera, faEllipsisV, faVolumeUp,
          faVolumeMute, faTimesCircle, faPlayCircle, faPauseCircle,
          faAngleRight, faAngleLeft, faCheckCircle, faHandPointLeft,
          faReadme, faBell, faHome, faQuestionCircle, faLock, faVideo,
          faVolumeMute, faVolumeUp, faMicrophoneSlash, faMicrophone,
          faCommentAlt,faVideo, faVideoSlash, faExpand, faCompress, faEye, faEyeSlash
          )

ReactDOM.render(
  <React.StrictMode>
    <MainRouteApp />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
