import React, { useEffect, useState } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useStateWithLocalStorage } from "../hooks/token_hooks";
import { learnerDetailsCall } from "../network/learner/learner_manager";

const ProtectedRoute = ({ children, ...rest }) => {
    const [isAuth, setIsAuth] = useState(0);
    const [token, setMToken] = useStateWithLocalStorage("learner_token");
    const [learnerDetails, setLearnerDetails] = useState();

    let location = useLocation();

    
    
    useEffect(()=>{
        // console.log('token', token)
        const getLearnerDetails = async ()=>{
            await learnerDetailsCall(token)
            .then((response)=>{
                if(response.ok){
                    return response.json();
                }
                setMToken('');
                setIsAuth(2);
                
            })
            .then((json_resp)=>{
                setLearnerDetails(json_resp);
                setIsAuth(1);
                
            }).catch((e)=>{
                console.log(e)
                setIsAuth(3);
                
            })
        }
        getLearnerDetails();
    }, [setMToken, token])

    
    let renderChildren = ()=>{
        if(isAuth===2){
            return <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />;
        }
        if(isAuth===0){
            return (
                <div className="w-100 vh-100 d-flex align-items-center justify-content-center ">
                    <span className="spinner-grow spinner-grow bg-primary me-2" role="status" aria-hidden="true"></span>
                </div>
            )
        }
        if(isAuth===3){
            return (
                <div className="w-100 vh-100 d-flex align-items-center justify-content-center ">
                    <h3 className="text-danger">Failed to Load</h3>
                </div>
                
            )
        }
        return React.Children.map(children, child =>
            React.cloneElement(child, { learnerDetails: learnerDetails, setLearnerDetails:setLearnerDetails, existingProp: 'value' }));
    }
    return ( 
        
        <Route {...rest}>
            {renderChildren()}
        </Route>
          
     );
}
 
export default ProtectedRoute;