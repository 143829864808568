import React, { useEffect, useState } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useStateWithLocalStorage } from "../../hooks/token_hooks";
import { quizCall } from "../../network/learner/learner_quiz_manager";

const ProtectedQuizRoute = ({ children, ...rest }) => {
    const [isAuth, setIsAuth] = useState(0);
    const [token] = useStateWithLocalStorage("learner_token");
    const [quizDetails, setQuizDetails] = useState();

    let location = useLocation();
    let { id } = rest.computedMatch.params;


    
    
    useEffect(()=>{
        // console.log('token', )
        const getLearnerDetails = async ()=>{
            await quizCall(token, id)
            .then((response)=>{
                if(response.ok){
                    return response.json();
                }
                setIsAuth(2);
                // console.log(response.status)
                
            })
            .then((json_resp)=>{
                setQuizDetails(json_resp);
                setIsAuth(1);
                // console.log(json_resp)
                
            }).catch((e)=>{
                console.log(e)
                setIsAuth(3);
                
            })
        }
        getLearnerDetails();
    }, [ token, id])

    
    let renderChildren = ()=>{
        if(isAuth===2){
            return <Redirect
            to={{
              pathname: "/learner-dashboard",
              state: { from: location }
            }}
          />;
        }
        if(isAuth===0){
            return (
                <div className="w-100 vh-100 d-flex align-items-center justify-content-center ">
                    <span className="spinner-grow spinner-grow bg-primary me-2" role="status" aria-hidden="true"></span>
                </div>
            )
        }
        if(isAuth===3){
            return (
                <h1>Failed to Load</h1>
            )
        }
        return React.Children.map(children, child =>
            React.cloneElement(child, { quizDetails: quizDetails, existingProp: 'value' }));
    }
    return ( 
        
        <Route {...rest}>
            {renderChildren()}
        </Route>
          
     );
}
 
export default ProtectedQuizRoute;