import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { base_url } from "../../network/utils";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {
  CustomImageRenderer,
  CustomVideoRenderer,
} from "../../helpers/renderers";

function AttachmentPreview({ attachment, handleClose }) {
  const attachmentUrl = `${base_url}storage/${attachment.url}`;

  const renderAttachmentContent = () => {
    return (
      <DocViewer
        documents={[{ uri: attachmentUrl }]}
        pluginRenderers={[
          ...DocViewerRenderers,
          CustomImageRenderer,
          CustomVideoRenderer,
        ]}
        config={{
          header: {
            disableHeader: false,
            disableFileName: true,
          },
        }}
        style={{ height: "65vh" }}
      />
    );

    /*  return (
        <div>
          <p>Unsupported file type</p>
          <p>
            But you can open the file in a browser or an appropriate application
            on your computer.
          </p>
          <a
            href={attachmentUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-sm btn-info"
          >
            <FontAwesomeIcon icon="file-alt" /> Open File
          </a>
        </div>
      ); */
  };

  return (
    <div className="attachment-preview-container">
      <div className="d-flex justify-content-between align-items-center attachment-preview-header">
        <p className="h3">Attachment Preview</p>
        <button className="btn btn-md close-button" onClick={handleClose}>
          <FontAwesomeIcon className="text-danger" icon="times" /> Close Preview
        </button>
      </div>
      <div className="attachment-content">{renderAttachmentContent()}</div>
    </div>
  );
}

export default AttachmentPreview;
