import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createRef, Fragment, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PopUp } from "../helpers/ui_comps";
import { useStateWithLocalStorage } from "../hooks/token_hooks";
import {
  learnerLoginCall,
  learnerSendOTPCall,
  learnerUpdateOTPCall,
} from "../network/learner/learner_auth";

const LoginPage = () => {
  const [currentForm, setCurrentForm] = useState(1);
  const [learnerEmail, setLearnerEmail] = useState("");
  const [message, setMessage] = useState(null);

  return (
    <div
      className="container-fluid vh-100 p-md-5"
      style={{ background: "#f8f9fa" }}
    >
      <div className="position-fixed end-0">
        <PopUp message={message} />
      </div>
      <div className="d-flex flex-row h-75 mt-4 border border-1 rounded ">
        <LeftSide />
        <RightSide
          show={currentForm === 1}
          setCurrentForm={setCurrentForm}
          setMessage={setMessage}
        />
        <ForgotPassword
          show={currentForm === 2}
          setCurrentForm={setCurrentForm}
          setLearnerEmail={setLearnerEmail}
          setMessage={setMessage}
        />
        <ResetOtp
          show={currentForm === 3}
          learnerEmail={learnerEmail}
          setCurrentForm={setCurrentForm}
          setMessage={setMessage}
        />
      </div>
    </div>
  );
};

const LeftSide = () => {
  let bgStyle = {
    backgroundImage: 'url("/meeting-and-conferencing.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    // backgroundColor:'#336699'
  };
  return (
    <div
      className="col-md-6 h-100  d-none d-md-block bg-primary"
      style={bgStyle}
    ></div>
  );
};
const RightSide = ({ show, setCurrentForm, setMessage, ...props }) => {
  const [loadingState, setLoadingState] = useState(false);
  const [token, setLocalLearnerToken] =
    useStateWithLocalStorage("learner_token");
  const history = useHistory();
  const location = useLocation();
  const [eyeState, setEyeState] = useState(false);
  const { from } = location.state || {
    from: { pathname: "/learner-dashboard" },
  };

  useEffect(() => {
    if (token) {
      history.replace(from);
    }
  }, [from, history, token]);
  let handleSubmit = (event) => {
    event.preventDefault();
    setLoadingState(true);
    if (loadingState) {
      setMessage({ type: "info", message: "still loading..." });
      return;
    }
    // let elements = event.target.elements;
    // for(let el of elements) el.readOnly = true;
    let data = new FormData(event.target);
    learnerLoginCall(data)
      .then((response) => response.json())
      .then((json_resp) => {
        setLoadingState(false);
        if (!json_resp.token) {
          setMessage({ type: "warning", message: json_resp.message });
          return;
        }
        setMessage({ type: "success", message: "Login Successful" });
        setLocalLearnerToken(json_resp.token);
        history.replace(from);
      })
      .catch((e) => {
        setMessage({ type: "danger", message: "An Error Occurred" });
        setLoadingState(false);
      });
    // createPolicyThrust();
  };
  let displayStyle = {
    transition: "visibility 0s, opacity 2s linear",
    visibility: show ? "visible" : "hidden",
    position: show ? "relative" : "absolute",
    opacity: show ? "1" : "0",
  };

  return (
    <div className="col-md-6 col-12 h-100 p-md-5 p-3" style={displayStyle}>
      <Link
        to="/"
        className="d-flex align-items-center justify-content-center mb-2 text-dark text-decoration-none"
      >
        <img src="/logo.png" className="w-25 my-5" alt="logo" />
      </Link>
      <form method="post" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Email address</label>
          <input
            type="email"
            name="email"
            className="form-control"
            required
            maxLength="255"
          />
        </div>
        <div className="mb-3 position-relative">
          <label className="form-label">Password</label>
          <input
            type={eyeState ? "text" : "password"}
            name="password"
            className="form-control"
            required
          />
          <FontAwesomeIcon
            onClick={() => {
              setEyeState((state) => !state);
            }}
            icon={eyeState ? "eye-slash" : "eye"}
            className={`position-absolute bottom-0 end-0 m-2 text-${
              eyeState ? "dark" : "primary"
            }`}
          />
        </div>

        <button type="submit" className="btn btn-primary text-light float-end">
          <span
            className={`spinner-grow spinner-grow-sm me-2 ${
              loadingState ? "" : "d-none"
            }`}
            role="status"
            aria-hidden="true"
          ></span>
          Login
        </button>
        <div onClick={() => setCurrentForm(2)} className="text-dark btn">
          <small>forgot password?</small>
        </div>
      </form>
    </div>
  );
};

const ForgotPassword = ({
  show,
  setCurrentForm,
  setMessage,
  setLearnerEmail,
  ...props
}) => {
  const [loadingState, setLoadingState] = useState(false);
  let displayStyle = {
    transition: "visibility 0s, opacity 2s linear",
    visibility: show ? "visible" : "hidden",
    position: show ? "relative" : "absolute",
    opacity: show ? "1" : "0",
  };

  let handleSubmit = (event) => {
    event.preventDefault();

    if (loadingState) {
      setMessage({ type: "info", message: "still loading..." });
      return;
    }
    setLoadingState(true);
    let data = new FormData(event.target);
    setLearnerEmail(event.target.email.value);
    learnerSendOTPCall(data)
      .then((response) => response.json())
      .then((json_resp) => {
        setLoadingState(false);
        if (json_resp.errors) {
          setMessage({ type: "warning", message: "Invalid Email Address" });
          return;
        }
        setMessage({ type: "success", message: json_resp.message });
        setCurrentForm(3);
      })
      .catch((e) => {
        setMessage({ type: "danger", message: "An Error Occurred" });
        setLoadingState(false);
      });
  };
  return (
    <div className="col-md-6  col-12 h-100 p-md-5 p-3" style={displayStyle}>
      <Link
        to="/"
        className="d-flex align-items-center justify-content-center mb-5 text-dark text-decoration-none"
      >
        <img src="/logo.png" className="w-50 my-5" alt="logo" />
      </Link>
      <form method="post" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Email address
          </label>
          <input
            type="email"
            name="email"
            maxLength="255"
            required
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
          <div id="emailHelp" className="form-text">
            We'll sent an OTP to your email to finish the process
          </div>
        </div>

        <button type="submit" className="btn btn-primary text-light float-end">
          <span
            className={`spinner-grow spinner-grow-sm me-2 ${
              loadingState ? "" : "d-none"
            }`}
            role="status"
            aria-hidden="true"
          ></span>
          Send OTP
        </button>
        <div
          onClick={() => setCurrentForm(1)}
          className="text-dark btn border border-1 "
        >
          <small>login</small>
        </div>
      </form>
    </div>
  );
};

const ResetOtp = ({
  show,
  setCurrentForm,
  setMessage,
  learnerEmail,
  ...props
}) => {
  const [loadingState, setLoadingState] = useState(false);
  const [showNewPassword, setShowNewPass] = useState(false);

  let handleSubmit = (event) => {
    event.preventDefault();

    if (loadingState) {
      setMessage({ type: "info", message: "still loading..." });
      return;
    }
    setLoadingState(true);
    setShowNewPass(true);
    let data = new FormData(event.target);
    let otp = "";
    for (let el of event.target.querySelectorAll(".digit")) otp += el.value;
    data.append("otp", otp);
    data.append("email", learnerEmail);

    let type = "warning";
    // return;
    learnerUpdateOTPCall(data)
      .then((response) => {
        if (response.ok) type = "success";
        return response.json();
      })
      .then((json_resp) => {
        setLoadingState(false);
        if (json_resp.errors) {
          setMessage({ type: type, message: "Invalid Email Address" });
          return;
        }
        setMessage({ type: type, message: json_resp.message });
        if (type === "success") setCurrentForm(1);
      })
      .catch((e) => {
        setMessage({ type: "danger", message: "An Error Occurred" });
        setLoadingState(false);
      });
  };

  let formRef = createRef();
  let displayStyle = {
    transition: "visibility 0s, opacity 2s linear",
    visibility: show ? "visible" : "hidden",
    position: show ? "relative" : "absolute",
    opacity: show ? "1" : "0",
  };

  let handleDigitEntered = (e) => {
    if (e.keyCode >= 48 && e.keyCode <= 57) {
      if (e.target.value.length >= 1) {
        e.target.value = e.target.value.substring(0, 1);
        var nextEl = e.target.nextElementSibling;
        if (nextEl) {
          nextEl.focus();
          setShowNewPass(false);
        } else {
          setShowNewPass(true);
        }
      }
    } else {
      e.preventDefault();
      return;
    }
  };

  let getNewPassword = !showNewPassword ? (
    <Fragment></Fragment>
  ) : (
    <div className="mb-3">
      <label className="form-label">New Password</label>
      <input
        type="password"
        name="password"
        minLength="6"
        className="form-control"
        required
      />
    </div>
  );
  return (
    <div className="col-md-6  col-12 h-100 p-md-5 p-3" style={displayStyle}>
      <Link
        to="/"
        className="d-flex align-items-center justify-content-center mb-5 text-dark text-decoration-none"
      >
        <img src="/logo-dark.png" className="w-50 my-5" alt="logo" />
      </Link>
      <form ref={formRef} method="post" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">OTP</label>
          <div className="d-flex align-items-center justify-content-center flex-row">
            <input
              type="number"
              name="otp_digit[]"
              className="form-control digit"
              required
              max="10"
              onKeyUp={handleDigitEntered}
            />
            <input
              type="number"
              name="otp_digit[]"
              className="form-control digit"
              required
              max="10"
              onKeyUp={handleDigitEntered}
            />
            <input
              type="number"
              name="otp_digit[]"
              className="form-control digit"
              required
              max="10"
              onKeyUp={handleDigitEntered}
            />
            <input
              type="number"
              name="otp_digit[]"
              className="form-control digit"
              required
              max="10"
              onKeyUp={handleDigitEntered}
            />
            <input
              type="number"
              name="otp_digit[]"
              className="form-control digit"
              required
              max="10"
              onKeyUp={handleDigitEntered}
            />
            <input
              type="number"
              name="otp_digit[]"
              className="form-control digit"
              required
              max="10"
              onKeyUp={handleDigitEntered}
            />
          </div>
          {getNewPassword}
        </div>

        <button type="submit" className="btn btn-primary text-light float-end">
          <span
            className={`spinner-grow spinner-grow-sm me-2 ${
              loadingState ? "" : "d-none"
            }`}
            role="status"
            aria-hidden="true"
          ></span>
          Submit OTP
        </button>
        <div onClick={() => setCurrentForm(2)} className="text-dark btn ">
          <small>change email?</small>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
