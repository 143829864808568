import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HeaderPanel, Main } from "./main_comp";
import LiveSessionVideo from "./video_call";

const LiveSessionPage = ({learnerDetails, ...props}) => {
    let { id } = useParams();
    const [currentSession, setCurrentSession] = useState();
    const [showSessions, setShowSessions] = useState(false);
    const [showLiveSession, setShowLiveSession] = useState(false);
    

    useEffect(() => {
        
        setCurrentSession(learnerDetails.organization.active_live_sessions.find( (session)=>{return session.id === parseInt(id)} ))
    }, [id, learnerDetails.organization.active_live_sessions]);
    return ( 
        <div className="d-flex vh-100 flex-column align-items-center justify-content-center">
            <HeaderPanel
                currentSession={currentSession}
                setShowSessions={setShowSessions}
                showSessions={showSessions}

                setShowLiveSession={setShowLiveSession}
                showLiveSession={showLiveSession}
            />
            <Main
                learnerDetails={learnerDetails}
                currentSession={currentSession}
                

                showSessions={showSessions}
                showLiveSession={showLiveSession}
            />
            {showLiveSession?<LiveSessionVideo
                    live_session={id}
                    setShowLiveSession={setShowLiveSession}
                    
            />:''}
            
        </div>
     );
}

 

 


 





 
export default LiveSessionPage;