import ReactPlayer from "react-player";
import PinchZoomPan from "react-image-zoom-pan";

const CustomImageRenderer = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return (
    <div className="w-100">
      <PinchZoomPan>
        <img src={currentDocument.uri} alt="Attachment" />
      </PinchZoomPan>
    </div>
  );
};

CustomImageRenderer.fileTypes = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "image/png",
  "image/jpeg",
  "image/gif",
];
CustomImageRenderer.weight = 1;

const CustomVideoRenderer = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;
  return (
    <div className="w-100 h-full d-flex justify-content-center align-items-center">
      <ReactPlayer
        url={currentDocument.uri}
        controls={true}
        className="w-100"
      />
    </div>
  );
};

CustomVideoRenderer.fileTypes = [
  "mp4",
  "webm",
  "ogg",
  "video/mp4",
  "video/webm",
  "video/ogg",
];
CustomVideoRenderer.weight = 1;

export { CustomImageRenderer, CustomVideoRenderer };
