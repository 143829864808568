import { Fragment, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useStateWithLocalStorage } from "../../hooks/token_hooks";
import { learnerLogoutCall } from "../../network/learner/learner_manager";
import "react-calendar/dist/Calendar.css";
import { base_url } from "../../network/utils";
import { ContentPanel, IntroPanel, QuizzesPanel } from "./dashboard_utils";
import { CoursePanel, RequirementAndOutcomePanel } from "./left_sidebar";
import { ProgressPanel } from "./right_sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = ({
  learnerDetails,
  setShowCourseList,
  showCourseList,
  setShowCourseProgress,
  currentLesson,
  ...props
}) => {
  const [loadingLogoutState, setLoadingLogoutState] = useState(false);
  const [token, setMToken] = useStateWithLocalStorage("learner_token");
  let history = useHistory();
  let { url } = useRouteMatch();
  const { from } = { from: { pathname: "/login" } };
  const logoutLearner = async () => {
    if (loadingLogoutState) return;
    setLoadingLogoutState(true);
    await learnerLogoutCall(token)
      .then((response) => response.json())
      .then((json_resp) => {
        setMToken("");
        history.replace(from);
      })
      .catch((e) => {
        console.log(e);
        setLoadingLogoutState(false);
      });
  };

  let toggleCourseList = () => {
    setShowCourseList(!showCourseList);
  };
  let toggleCourseProgress = () => {
    setShowCourseProgress((showCourseProgress) => !showCourseProgress);
  };
  return (
    <div className="row bg-white noisy-dashboard">
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3">
        <Link
          to="/"
          className="d-md-flex d-none align-items-center justify-content-md-left justify-content-start
                 col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
        >
          <img src="/logo.png" className="w-25" alt="logo" />
        </Link>

        <div className="d-flex flex-wrap align-items-center justify-content-end col-md-4 col-12">
          {!showCourseList ? (
            <FontAwesomeIcon
              className="text-dark d-md-none fs-4"
              icon="bars"
              onClick={() => toggleCourseList()}
            />
          ) : (
            <FontAwesomeIcon
              className="text-dark d-md-none fs-4"
              icon="times"
              onClick={() => toggleCourseList()}
            />
          )}
          <div className="flex-grow-1 d-md-none"></div>
          <Link
            to="/"
            className="text-center me-2 text-dark text-decoration-none"
          >
            <div
              className="border rounded-circle d-inline-block overflow-hidden"
              style={{ width: "35px" }}
            >
              <img src="/home.png" className="w-100" alt="Home icon" />
            </div>
          </Link>

          <div className="btn-group">
            <button
              className="btn btn-transparent dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div
                className="border rounded-circle d-inline-block overflow-hidden"
                style={{ width: "35px" }}
              >
                <img
                  src={
                    learnerDetails.img_url
                      ? `${base_url}storage/${learnerDetails.img_url}`
                      : "/avatar.png"
                  }
                  className="w-100"
                  alt="logo"
                />
              </div>
            </button>

            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <Link to={`${url}/profile`} className="dropdown-item">
                  <span>Profile</span>
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li className="text-center">
                <button
                  type="button"
                  className="btn btn-outline-primary me-2 "
                  onClick={() => logoutLearner()}
                >
                  <span
                    className={`spinner-grow spinner-grow-sm me-2 ${
                      loadingLogoutState ? "" : "d-none"
                    }`}
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Logout
                </button>
              </li>
            </ul>
          </div>
          {currentLesson ? (
            <FontAwesomeIcon
              onClick={() => toggleCourseProgress()}
              className="mx-4 d-md-none fs-4"
              icon="ellipsis-v"
            />
          ) : (
            <Fragment></Fragment>
          )}
        </div>
      </header>
    </div>
  );
};

const Main = ({
  setViewedObject,
  setShowCourseList,
  setShowCourseProgress,
  courses,
  isCourseloading,
  learnerDetails,
  currentCourseRequirements,
  currentLesson,
  setCurrentLesson,
  showCourseList,
  setCurrentCourseRequirements,
  showCourseProgress,
  readCourseText,
  setReadCourseText,
  ...props
}) => {
  const [attachmentToPreview, setAttachmentToPreview] = useState(null);

  let generalRender = () => {
    if (currentCourseRequirements) {
      if (currentCourseRequirements.show_quiz) {
        return (
          <QuizzesPanel
            currentCourseQuizzes={currentCourseRequirements}
            courses={courses}
          />
        );
      }
      return (
        <RequirementAndOutcomePanel
          currentCourseRequirements={currentCourseRequirements}
        />
      );
    }
    if (currentLesson)
      return (
        <Fragment>
          <ContentPanel
            lesson={currentLesson}
            courses={courses}
            setCurrentLesson={setCurrentLesson}
            setCurrentCourseRequirements={setCurrentCourseRequirements}
            readCourseText={readCourseText}
            setViewedObject={setViewedObject}
            setAttachmentToPreview={setAttachmentToPreview}
            attachmentToPreview={attachmentToPreview}
          />

          <ProgressPanel
            courses={courses}
            learnerDetails={learnerDetails}
            showCourseProgress={showCourseProgress}
            currentLesson={currentLesson}
            setShowCourseProgress={setShowCourseProgress}
            setAttachmentToPreview={setAttachmentToPreview}
            attachmentToPreview={attachmentToPreview}
          />
        </Fragment>
      );
    return <IntroPanel learnerDetails={learnerDetails} />;
  };

  return (
    <div className="row flex-grow-1">
      <CoursePanel
        setShowCourseList={setShowCourseList}
        setViewedObject={setViewedObject}
        courses={courses}
        loadingCourse={isCourseloading}
        currentLesson={currentLesson}
        setCurrentLesson={setCurrentLesson}
        showCourseList={showCourseList}
        setCurrentCourseRequirements={setCurrentCourseRequirements}
        currentCourseRequirements={currentCourseRequirements}
        readCourseText={readCourseText}
        setReadCourseText={setReadCourseText}
        learnerDetails={learnerDetails}
      />
      {generalRender()}
    </div>
  );
};

export { Header, Main };
