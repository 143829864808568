import { Fragment, useEffect, useState } from "react";
import { Link, } from "react-router-dom";
import  ResultPanel  from "./quiz_result";
import { NumberBox, QuizIntroPanel, OptionList, TimerCountDown, QuizSubmittedPanel,
         QuizProgressPanel, ShortAnswerOption, FooterPanel } from "./quiz_utils";


const QuizPage = ({quizDetails, ...props}) => {


    const [viewState, setViewState] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState();

    const [currentQuestionResult, setCurrentQuestionResult] = useState();

    const [allQuestions, setAllQuestions] = useState([]);
    const [submissionData, setSubmissionData] = useState();
    

    useEffect(() => {
        setAllQuestions(quizDetails.questions.map( (question, index)=>{
            return {...question, number: index+1 }
        } ))
    }, [quizDetails]);

    useEffect(() => {
        if(!currentQuestion) return;
        setCurrentQuestion(allQuestions.find((question)=>question.id === currentQuestion.id) );
        
    }, [allQuestions, currentQuestion]);
    
    let renderMainView = ()=>{

        if (viewState  === 1) {
            return  <Main
                        currentQuestion={currentQuestion}
                        setCurrentQuestion={setCurrentQuestion}
                        allQuestions={allQuestions}
                        setAllQuestions={setAllQuestions}
                        setViewState={setViewState}

                        setSubmissionData={setSubmissionData}
                    />;
        }
        if (viewState  === 2) {
            return  <QuizSubmittedPanel
                        quizDetails={quizDetails}
                        setViewState={setViewState}
                        submissionData={submissionData}
                    />;
        }
        if (viewState === 3 ){
            return <ResultPanel
                        setViewState={setViewState}
                        allQuestions={allQuestions}
                        currentQuestionResult={currentQuestionResult}
                    />
        }
        return <QuizIntroPanel 
                    quizDetails={quizDetails}
                    setViewState={setViewState}

                    setCurrentQuestion={setCurrentQuestion}
                    allQuestions={allQuestions}

                    setCurrentQuestionResult={setCurrentQuestionResult}
                />    
    }
    

    return ( 
        <Fragment>
            <header className="d-flex flex-row flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-md-4 border-bottom noisy-dashboard">
                <Link to="/learner-dashboard" className="d-md-flex d-inline align-items-center justify-content-md-left justify-content-center
                 col-md-3 mb-2 mb-md-0 col-4 text-dark text-decoration-none">
                <img src="/logo.png" className="w-25" alt="logo" />
                </Link>

                
                <div className="flex-md-grow-1 text-start text-md-center d-md-block d-inline">
                    <h1 className="text-bold d-md-block d-none m-0 engraved">Self Assesment Quiz!</h1>
                    <h4 className="text-muted m-0 engraved">{quizDetails.title}</h4>
                </div>
                <div className="mt-3 mt-md-0 d-md-flex flex-grow-1 d-inline flex-wrap align-items-center justify-content-center col-md-3">
                        {viewState  === 1?
                            <TimerCountDown
                                quizDetails={quizDetails}
                                allQuestions={allQuestions}
                                setSubmissionData={setSubmissionData}
                                setViewState={setViewState}
                            />:
                            <Fragment/>
                        }
                        
                </div>
                
                
            </header>
            {renderMainView()}
            
        </Fragment>

     );
}



const Main = ({currentQuestion, allQuestions, 
    setCurrentQuestion, setAllQuestions, setViewState, setSubmissionData, ...props}) => {
    var createMarkup = ()=>{
        if(currentQuestion.type !== 'fill_in'){
            return {__html: currentQuestion.quiz_question_title};
        }
        let selectOption = currentQuestion.options.find((option)=>{
            if (currentQuestion.selectedOptions && currentQuestion.selectedOptions.length > 0 && currentQuestion.selectedOptions[0] ===  option.id){
                return true;
            }
            return false;
        })
        let inputGap = `<input type="text" value="${selectOption?selectOption.quiz_option_title:''}" disabled className="form-control" />`;
        
        return {__html: currentQuestion.quiz_question_title.replaceAll('__', inputGap)};
    }
    

    let renderOptionType = ()=>{
        if(currentQuestion.type === 'short_answer') return <ShortAnswerOption
            currentQuestion={currentQuestion}
            setAllQuestions={setAllQuestions}
            allQuestions={allQuestions}
        />
        return <OptionList
                    currentQuestion={currentQuestion}
                    setAllQuestions={setAllQuestions}
                    allQuestions={allQuestions}
                />
    }
    return ( 
        <div className="row">
            <div className="col-lg-8 col-md-7 ps-md-5 pt-1">
                <div className="card pb-5 ms-lg-5 pt-2 shadow border-0">
                    <div className="card-body p-1 row">
                        <div className="col-1 text-center">
                            {currentQuestion.number}
                        </div>
                        <div className="col-11">
                            <div className="w-100 content-displayer" dangerouslySetInnerHTML={createMarkup()} />
                            
                            {renderOptionType()}
                            
                        </div>
                        <div className="col-10 offset-1 border-top my-2">
                        </div>
                        <FooterPanel
                            currentQuestion={currentQuestion}
                            setCurrentQuestion={setCurrentQuestion}
                            allQuestions={allQuestions}
                            setViewState={setViewState}
                            setSubmissionData={setSubmissionData}
                        />
                    </div>
                    
                </div>
            </div>
            <div className="col-lg-4 col-md-5 pe-md-5 pt-1">
                <div className="card pb-5 me-lg-5 pt-2 shadow border-0">
                    <div className="card-body p-1">
                        <NumberBox
                            allQuestions={allQuestions}
                            setCurrentQuestion={setCurrentQuestion}
                            currentQuestion={currentQuestion}
                        />
                    </div>
                    
                </div>
                <QuizProgressPanel
                    allQuestions={allQuestions}
                />
            </div>
            
        </div>
     );
}
 




 
export default QuizPage;