import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Moment from "react-moment";


const ResultPanel = ({allQuestions, currentQuestionResult, setViewState, ...props}) => {

    let checkCorrectAnswers = allQuestions.map((question)=>{
        let tmpQuestion = currentQuestionResult.session_questions.find((s_question)=>{
            return s_question.quiz_question_id === question.id;
        })
        let selectedShortAnswer =  "";
        if(tmpQuestion && tmpQuestion.short_answers){
            tmpQuestion.short_answers.forEach((short_answer)=>{
                selectedShortAnswer += short_answer.content;
            })
        } 
        let newOptions = question.options.map((option)=>{
            
            let selected =  false;
            
            if(tmpQuestion){
                tmpQuestion.options.forEach((m_option)=>{
                    if(m_option.quiz_option_id === option.id){
                        selected = true;
                    }
                })
                
            }
            return {...option, selected:selected};
        });
        return {...question, options:newOptions, selectedShortAnswer:selectedShortAnswer};
    });
    

    
    let renderAllQuestions = () =>{
        return checkCorrectAnswers.map((question)=>{
            return  <QuizResultQuestion
                        key={question.id}
                        question={question}
                        />
        })
    }
    
    return ( 
        <Fragment>
            <QuizScore
                setViewState={setViewState}
                checkCorrectAnswers={checkCorrectAnswers}
                currentQuestionResult={currentQuestionResult}
                />
            <div className="row">
                {renderAllQuestions()}
            </div>
        </Fragment>
     );
}


const QuizResultQuestion = ({question}) => {
    var createMarkup = (currentQuestion)=>{
        if(currentQuestion.type !== 'fill_in'){
            return {__html: currentQuestion.quiz_question_title};
        }
        let selectOption = currentQuestion.options.find((option)=>{
            if (option.is_correct === 1 && option.selected){
                return true;
            }
            return false;
        })
        let inputGap = `<input type="text" value="${selectOption?selectOption.quiz_option_title:''}" disabled className="form-control" />`;
        
        return {__html: currentQuestion.quiz_question_title.replaceAll('__', inputGap)};
    }

    return ( 
        <div className="col-md-6 ps-md-2 pt-1">
            <div className="card pb-5 ms-lg-5 pt-2 shadow border-0">
                <div className="card-body p-1 row">
                    <div className="col-1 text-end">
                        {question.number}
                    </div>
                    <div className="col-11">
                        <div className="w-100 content-displayer" 
                            dangerouslySetInnerHTML={createMarkup(question)} />
                        
                        <QuizResultQuestionOption
                            currentQuestion={question}
                        />
                        
                    </div>

                    
                </div>
                
            </div>
        </div>
     );
}

const QuizResultQuestionOption = ({currentQuestion}) => {
    let renderOptionType = ()=>{
        if(currentQuestion.type === 'short_answer') return (
            <div className="col-sm-10 mt-3">
                <textarea name="" id="" cols="30" defaultValue={currentQuestion.selectedShortAnswer} rows="10" className="form-control me-2"/>
            </div>
        );
        
        return <CorrectOptionList
                    currentQuestion={currentQuestion}
                />
    }
    return renderOptionType();
}
 
const CorrectOptionList = ({currentQuestion, ...props}) => {


    
    let renderIsCorrectIcon = (option)=>{
        if(option.is_correct===1){
            return <FontAwesomeIcon className="text-success" size="2x" icon="check-circle" />;
        }
        return option.selected && option.is_correct!==1?<FontAwesomeIcon className="text-danger"  size="2x" icon="times-circle" />:<Fragment/>;
        // return  <FontAwesomeIcon className="text-danger" size="2x" icon="times" />;
    }
    let renderOptions = ()=>{
        return currentQuestion.options.map((option)=>{
            return  <li key={option.id} className="list-group-item border-0 d-flex w-100 align-items-center justify-content-center p-1">
                        {renderIsCorrectIcon(option)}
                       
                        <span className="flex-grow-1 mx-2">{option.quiz_option_title}</span>
                        {/* {option.selected?<FontAwesomeIcon className={`${option.is_correct===1? 'text-success': 'text-danger'} me-3`}  size="2x" icon="hand-point-left" />:<Fragment/>} */}
                        
                    </li>;
        })
        
    }

    return ( 
        <div className="col-12 mt-2">
            <ul className="list-group list-group-flush">
                {renderOptions()}
            </ul>
        </div>
     );
}

const QuizScore = ({setViewState, checkCorrectAnswers, currentQuestionResult, ...props}) => {
    let corrects = 0;
    checkCorrectAnswers.forEach((question)=>{
        if(question.selectedShortAnswer.length > 0){
            corrects ++;
        }else{
            let option_c = question.options.find((option)=>{
                return option.is_correct === 1 && option.selected;
            })
            if(option_c) corrects ++;
        }
    })
    const [progress, setProgress] = useState(7);
    let options = {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "40%",
              },
              track: {
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  blur: 4,
                  opacity: 0.15
                }
              },
              dataLabels: {
                name: {
                  offsetY: 60,
                  color: "#000",
                  fontSize: "13px",
                  show: false
                },
                value: {
                    offsetY: 10,
                    color: "#000",
                    fontSize: "20px",
                    show: true
                }
              }
            }
        },
        fill: {
        type: "gradient",
        gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#87D4F9"],
            stops: [0, 100]
        }
        },
        stroke: {
        lineCap: "round"
        },
        labels: [' '],
    };
    let series = [progress];
    useEffect(() => {
        setProgress( Math.round((corrects/checkCorrectAnswers.length)*100) )
    }, [checkCorrectAnswers.length, corrects]);
    return ( 
        <div className="row">
                <div className="col-sm-8 d-flex align-items-center justify-content-center text-center ">
                    <button className="btn btn-transparent text-primary ms-2" onClick={()=>setViewState(0)}>
                        <FontAwesomeIcon className="text-success" size="4x" icon="angle-left" />
                    </button>
                    <small className="fs-6 text-muted text-center ms-2 "><Moment format="D MMM YYYY" withTitle>{currentQuestionResult.created_at}</Moment></small>
                    <small className="fs-4 flex-grow-1 ">{`${corrects} out of ${checkCorrectAnswers.length}`}</small>
                    
                </div>
                <ReactApexChart className="col-sm-4" options={options} series={series} type="radialBar" height={150} />
                
            </div>
     );
}
 

export default ResultPanel ;