import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef, Fragment } from "react";

const Participant = ({ participant, highlightedParticipant, 
  setHighlightedParticipant, localParticipant }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  
  const [micOpen, setMicOpen] = useState(true);
  const [videoOpen, setVideoOpen] = useState(true);

  const videoRef = useRef();
  const audioRef = useRef();


 

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);


  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
  }, [participant, highlightedParticipant]);
  
  useEffect(() => {
    
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      // console.log('trackSubscribed', track);
      if (track.kind === "video") {
        track.on('disabled', () => {
          setVideoOpen(false);
        });
        track.on('enabled', () => {
          setVideoOpen(true);
        });
        if(!track.isEnabled){
          setVideoOpen(false);
        }
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        track.on('disabled', () => {
          setMicOpen(false);
        });
        track.on('enabled', () => {
          setMicOpen(true);
        });
        if(!track.isEnabled){
          setMicOpen(false);
        }
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[videoTracks.length-1];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  let handleClick = ()=>{
    if(setHighlightedParticipant){
      setHighlightedParticipant(participant.sid)
    }
  }
  if(!participant) return<Fragment/>
  return (
    <div onClick={handleClick} className={` ${highlightedParticipant===participant.sid?'border-primary':'' } border btn border-5 border-transparent d-inline-block p-cont position-relative`}>
      <small className="position-absolute text-secondary fw-bolder" title={participant.identity}>{participant.identity}</small>
        <div className={`${videoOpen?'d-none':''} w-100 h-100 d-flex align-items-center justify-content-center text-uppercase fw-bolder  fs-4`}>
          {participant.identity.split(' ').map(name=>name[0]).join('')}
        </div>
        <video className={`${!videoOpen?'d-none':''}  w-100 h-100`} ref={videoRef} autoPlay={true} />
      
      <audio ref={audioRef} autoPlay={true}  />
      {participant.identity.includes("Instructor_")?
        <FontAwesomeIcon icon="user-shield" className ="position-absolute top-0 end-0 text-warning m-2"/>
    :''}
      <div className="position-absolute bottom-0 mute-icon w-100 d-flex justify-content-between">
        <div className="d-block">
            {localParticipant && localParticipant.sid !== participant.sid?<FontAwesomeIcon className={`text-${micOpen?'primary':'dark'}`} icon={!micOpen?"microphone-slash":"microphone"} />:''}
            {localParticipant && localParticipant.sid !== participant.sid?<FontAwesomeIcon className={`mx-2 text-${videoOpen?'primary':'dark'}`} icon={!videoOpen?"video-slash":"video"} />:''}
        </div>

        
        
      </div>
    </div>
  );
};

export default Participant;