import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";
import Video from 'twilio-video';
import LocalParticipant from "./local_participate";
import Participant from "./participate";

const Room = ({token, setShowLiveSession, ...props}) => {
    const [micOpen, setMicOpen] = useState(true);
    const [videoOpen, setVideoOpen] = useState(true);
    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]); 
    const [highlightedParticipant, setHighlightedParticipant] = useState();
    useEffect(() => {
        const participantConnected = participant => {
          setParticipants(prevParticipants => [...prevParticipants, participant]);
        };
    
        const participantDisconnected = participant => {
          setParticipants(prevParticipants =>
            prevParticipants.filter(p => p !== participant)
          );
        };

        const handleDisconnet =   room => {
            // Detach the local media elements
            room.localParticipant.tracks.forEach(publication => {
              const attachedElements = publication.track.detach();
              attachedElements.forEach(element => element.remove());
            });
            // setShowLiveSession(false);
            setShowLiveSession(false);
        }
    
        Video.connect(token, {
        //   name: roomName
        }).then(room => {
          setRoom(room);
          // console.log('room', room);
          room.on('participantConnected', participantConnected);
          room.on('participantDisconnected', participantDisconnected);
          room.on('disconnected', handleDisconnet);
          room.participants.forEach(participantConnected);
        }, error => {
            // console.error(`Unable to connect to Room: ${error.message}`);
            setShowLiveSession(false);
          });
    
        return () => {
          setRoom(currentRoom => {
            if (currentRoom && currentRoom.localParticipant.state === 'connected') {
              currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
                trackPublication.track.stop();
              });
              currentRoom.disconnect();
              return null;
            } else {
              return currentRoom;
            }
          });
        };
      }, [setShowLiveSession, token]);
    // const remoteParticipants = [room.localParticipant , ...participants].map(participant => (
    //     <Participant key={participant.sid} participant={participant} />
    // ));
    

    useEffect(() => {
      if(room){
        room.localParticipant.audioTracks.forEach(publication => {
          if(micOpen){
            publication.track.enable()
          }else{
            publication.track.disable()
          }
        });
      }
    }, [micOpen, room]);

    useEffect(() => {
      if(room){
        room.localParticipant.videoTracks.forEach(publication => {
          if(videoOpen){
            publication.track.enable()
          }else{
            publication.track.disable()
          }
        });
      }
    }, [videoOpen, room]);
    const renderAllParticipant = ()=>{
      let allParticipant = [];
      if(room){
        allParticipant = [...participants];
      }
      return allParticipant.map((participant, index) => (
          <Participant 
          key={participant.sid} 
          participant={participant}
          localParticipant={room.localParticipant}
          highlightedParticipant={highlightedParticipant}
          setHighlightedParticipant={setHighlightedParticipant}
          
           />
      ));
    }

    const renderLocalFeed = ()=>{
      if(!highlightedParticipant) return <Fragment/>
      return (     
            <LocalParticipant participant={participants.find(participant=>participant.sid=== highlightedParticipant)}
            />
      );
    }


    if(!room) return (
        <h6>
            connecting...
            <span className="spinner-grow spinner-grow-sm ms-2 text-success" role="status" aria-hidden="true"></span>
        </h6>
    )
    
    return (
        <Fragment>
            <div className="flex-grow-1 w-100" style={{height:'70vh'}}>
                {renderLocalFeed()}
            </div>
            <div className="w-100 particitants-container">
                {renderAllParticipant()}
            </div>
            
            <div className="position-fixed col-3 start-0 top-0">
                <LocalParticipant
                  participant={room.localParticipant}
                  />
                <div className="w-100 m-2 ps-3 position-absolute top-0 mute-icon">
                  <FontAwesomeIcon onClick={()=>{setMicOpen(old=>!old)}} className="text-primary mx-2" size="2x" icon={!micOpen?"microphone-slash":"microphone"} />
                  <FontAwesomeIcon onClick={()=>{setVideoOpen(old=>!old)}} className="text-primary mx-2" size="2x" icon={!videoOpen?"video-slash":"video"} />
                </div>
            </div>
            
        </Fragment>
        
     );
}
 
export default Room;