import { host_url } from "../utils"

const quizCall = (token, id) => {
    let headerList = {
        "Authorization": `Bearer ${token}`,
        "accept": "application/json",
      }
    return fetch(`${host_url}/learner/quiz/${id}`, {
        method:'GET',
        headers: headerList,
    })
}

const createQuizSessionCall = (token, data) => {
    let headerList = {
        "Authorization": `Bearer ${token}`,
        "accept": "application/json",
      }
    return fetch(`${host_url}/learner/update`, {
        method:'POST',
        headers: headerList,
        body: data
    })
}

const submitQuizSessionCall = (token, id, data) => {
    let headerList = {
        "Authorization": `Bearer ${token}`,

        "accept": "application/json",
        "Content-Type": "application/json",
      }
    return fetch(`${host_url}/learner/quiz/${id}/save-session-data`, {
        method:'POST',
        headers: headerList,
        body: JSON.stringify({
            questions: data
        })
    })
}





export {quizCall, createQuizSessionCall, submitQuizSessionCall };