import { host_url } from "../utils"


const getNewMessagesCall = (token, live_session, last_id) => {
    let headerList = {
        "Authorization": `Bearer ${token}`,
        "accept": "application/json",
      }
    return fetch(`${host_url}/learner/live_session/${live_session}/messages?last_id=${last_id}`, {
        method:'GET',
        headers: headerList,
    })
}

const sendMessageCall = (token, live_session, data) => {
    let headerList = {
        "Authorization": `Bearer ${token}`,
        "accept": "application/json",
      }
    return fetch(`${host_url}/learner/live_session/${live_session}/send-message`, {
        method:'POST',
        headers: headerList,
        body: data
    })
}

const getLiveSessionTokenCall = (token, live_session) => {
    let headerList = {
        "Authorization": `Bearer ${token}`,
        "accept": "application/json",
      }
    return fetch(`${host_url}/learner/live_session/${live_session}/token`, {
        method:'GET',
        headers: headerList,
    })
}







export {sendMessageCall, getNewMessagesCall, getLiveSessionTokenCall };