import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Calendar from "react-calendar";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const AttachmentGroups = ({
  currentLesson,
  currentAttachmentPreview,
  setAttachmentToPreview,
}) => {
  const isActiveAttachment = (attachment) =>
    currentAttachmentPreview && currentAttachmentPreview.id === attachment.id;

  const renderAttachments = (attachments) => {
    if (attachments.length === 0) {
      return (
        <span key={0} className="list-group-item fs-8 p-1">
          No Attachments Available
        </span>
      );
    }

    return attachments.map((attachment, index) => (
      <button
        key={attachment.id}
        className={`list-group-item fs-8 p-1 ${
          isActiveAttachment(attachment) ? "active" : ""
        }`}
        aria-current={isActiveAttachment(attachment)}
        onClick={() => setAttachmentToPreview(attachment)}
      >
        File {index + 1} ({attachment.type})
      </button>
    ));
  };

  let getAttachmentType = (type_list) => {
    return currentLesson.attachments.filter((attachment) => {
      return type_list.indexOf(attachment.type) !== -1;
    });
  };

  let getAllOthers = () => {
    return currentLesson.attachments.filter((attachment) => {
      return (
        attachment.type !== "zip" &&
        attachment.type !== "pdf" &&
        attachment.type !== "mp4" &&
        attachment.type !== "webm"
      );
    });
  };
  return (
    <div className=" card-body  row p-0 py-4 w-100">
      <div
        className="col-6 row m-0 p-0"
        data-bs-toggle="collapse"
        href="#collapse_pdf_list"
        role="button"
        aria-expanded="false"
        aria-controls="collapse_pdf_list"
      >
        <div className="col-4">
          <FontAwesomeIcon className="text-danger" icon="file-pdf" />
        </div>
        <div className="col-8 fs-7 p-0 ps-1">
          <b className=" d-block">Pdf</b>
          <small>{getAttachmentType(["pdf"]).length} files</small>
        </div>
      </div>
      <div
        className="col-6 row m-0 p-0"
        data-bs-toggle="collapse"
        href="#collapse_zip_list"
        role="button"
        aria-expanded="false"
        aria-controls="collapse_zip_list"
      >
        <div className="col-4">
          <FontAwesomeIcon className="text-info" icon="file-archive" />
        </div>
        <div className="col-8 fs-7 p-0 ps-1">
          <b className=" d-block">Zips</b>
          <small>{getAttachmentType(["zip"]).length} files</small>
        </div>
      </div>
      <div className="collapse" id="collapse_pdf_list">
        <div className="card card-body p-1">
          <ul className="list-group">
            {renderAttachments(getAttachmentType(["pdf"]))}
          </ul>
        </div>
      </div>
      <div className="collapse" id="collapse_zip_list">
        <div className="card card-body p-1">
          <ul className="list-group">
            {renderAttachments(getAttachmentType(["zip"]))}
          </ul>
        </div>
      </div>
      <div
        className="col-6 row m-0 p-0"
        data-bs-toggle="collapse"
        href="#collapse_video_list"
        role="button"
        aria-expanded="false"
        aria-controls="collapse_video_list"
      >
        <div className="col-4">
          <FontAwesomeIcon className="text-primary" icon="file-video" />
        </div>
        <div className="col-8 fs-7 p-0 ps-1">
          <b className=" d-block">Videos</b>
          <small>{getAttachmentType(["mp4", "webm"]).length} files</small>
        </div>
      </div>
      <div
        className="col-6 row m-0 p-0"
        data-bs-toggle="collapse"
        href="#collapse_other_list"
        role="button"
        aria-expanded="false"
        aria-controls="collapse_other_list"
      >
        <div className="col-4">
          <FontAwesomeIcon className="text-dark" icon="file-alt" />
        </div>
        <div className="col-8 fs-7 p-0 ps-1">
          <b className=" d-block">Others</b>
          <small>{getAllOthers().length} files</small>
        </div>
      </div>
      <div className="collapse" id="collapse_video_list">
        <div className="card card-body p-1">
          <ul className="list-group">
            {renderAttachments(getAttachmentType(["mp4", "webm"]))}
          </ul>
        </div>
      </div>
      <div className="collapse" id="collapse_other_list">
        <div className="card card-body p-1">
          <ul className="list-group">{renderAttachments(getAllOthers())}</ul>
        </div>
      </div>
    </div>
  );
};

const ProgressPanel = ({
  showCourseProgress,
  currentLesson,
  setShowCourseProgress,
  learnerDetails,
  setAttachmentToPreview,
  attachmentToPreview,
  courses,
  ...props
}) => {
  const [progress, setProgress] = useState(0);
  let options = {
    chart: {
      height: 350,
      type: "radialBar",
    },

    colors: ["#95be46"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "40%",
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 1,
            opacity: 0.15,
          },
        },
        dataLabels: {
          name: {
            offsetY: 60,
            color: "#000",
            fontSize: "13px",
            show: false,
          },
          value: {
            offsetY: 10,
            color: "#000",
            fontSize: "15px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Progress"],
  };
  let series = [progress];
  let filterAps = learnerDetails.organization.active_live_sessions.filter(
    (session) => {
      return session.lesson_id === currentLesson.id;
    }
  );
  const [currentCourse, setCurrentCourse] = useState(null);

  useEffect(() => {
    courses.forEach((course) => {
      if (course.id === currentLesson.course_id) {
        setCurrentCourse(course);
      }
    });
  }, [courses, currentLesson]);

  useEffect(() => {
    let viewCount = 0;
    if (!currentCourse) return;
    currentCourse.lessons.forEach((lesson) => {
      if (lesson.view && lesson.view.viewed === 1) viewCount++;
    });
    setProgress(Math.round((viewCount / currentCourse.lessons.length) * 100));
  }, [currentCourse]);

  let getLessonAppointments = () => {
    if (filterAps.length === 0)
      return (
        <div className="text-center fs-7 text-muted my-2">
          No Live Session Available
        </div>
      );
    return filterAps.map((session) => {
      return (
        <Link
          key={session.id}
          to={`live-session/${session.id}`}
          className="text-decoration-none"
        >
          <li className="list-group-item fs-7 mb-2 border-bottom p-1">
            Live Session <Moment fromNow>{session.session_date}</Moment>
            {session.external_link ? (
              <a
                href={session.external_link}
                target="_blank"
                className="btn btn-success p-0 m-0 fs-7"
                rel="noreferrer"
              >
                external link
              </a>
            ) : (
              ""
            )}
          </li>
        </Link>
      );
    });
  };
  const mark = filterAps.map((session) => {
    return moment(session.session_date).format("DD-MM-YYYY");
  });
  let handleParentClicked = (event) => {
    setShowCourseProgress(false);
  };

  let handleHolderClicked = (event) => {
    event.stopPropagation();
  };
  return (
    <span
      className="col-lg-2 col-md-2 p-0 dashboard-right-panel "
      onClick={handleParentClicked}
      style={{ right: showCourseProgress ? "0" : "" }}
    >
      <div className=" holder  " onClick={handleHolderClicked}>
        <h4 className="w-100 text-center mt-3 fs-6">
          {currentCourse ? currentCourse.title : "Course"} Progress
        </h4>
        <div className="card shadow-sm align-items-center justify-content-center">
          <ReactApexChart
            options={options}
            series={series}
            type="radialBar"
            height={150}
          />
          <h4 className="w-100 text-center mt-3 fs-7">Attachments</h4>
          <AttachmentGroups
            currentLesson={currentLesson}
            currentAttachmentPreview={attachmentToPreview}
            setAttachmentToPreview={setAttachmentToPreview}
          />
          <h4 className="w-100 text-center mt-3 fs-7">Live Session Schedule</h4>
          <ul className="list-group w-100">{getLessonAppointments()}</ul>
          <Calendar
            className="col-12 fs-8"
            tileClassName={({ activeStartDate, date, view }) => {
              if (mark.indexOf(moment(date).format("DD-MM-YYYY")) === -1)
                return null;
              return "bg-primary rounded-circle text-light";
            }}
          />
        </div>
      </div>
    </span>
  );
};

export { ProgressPanel };
