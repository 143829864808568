import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createRef, Fragment, useState } from "react";
import swal from "sweetalert";
import { useStateWithLocalStorage } from "../../hooks/token_hooks";
import { learnerUpdateCall } from "../../network/learner/learner_manager";
import { base_url } from "../../network/utils";

const Profile = ({learnerDetails, setLearnerDetails, ...props}) => {
    return ( 
        <div className="card col-sm-12 col-md-6 offset-md-3">
            <div className="card-body">
                <ProfileImage
                    setLearnerDetails={setLearnerDetails}
                    learnerDetails={learnerDetails}
                />
                <UserProperty
                    setLearnerDetails={setLearnerDetails}
                    field='Name'
                    name='name'
                    type='text'
                    learnerDetails={learnerDetails}
                />
                <UserProperty
                    setLearnerDetails={setLearnerDetails}
                    field='Email'
                    name='email'
                    type='email'
                    learnerDetails={learnerDetails}
                />
                <UserProperty
                    setLearnerDetails={setLearnerDetails}
                    field='Phone'
                    name='phone'
                    type='tel'
                    learnerDetails={learnerDetails}
                />

                <UserProperty
                    setLearnerDetails={setLearnerDetails}
                    field='Password'
                    name='password'
                    type='password'
                    learnerDetails={learnerDetails}
                />
                
            </div>
        </div>
     );
}

const ProfileImage = ({learnerDetails, setLearnerDetails, ...props}) => {
    const [message, setMessage] = useState('');
    const [loadingState, setLoadingState] = useState(false);
    const [token] = useStateWithLocalStorage("learner_token");
    let inputRef = createRef();
    let captureImage = ()=>{
        inputRef.current.click();
    }
    let handleImageChange = (event)=>{
        console.log(event)
        setMessage('');
        if(event.target.files[0].size > 1024000            ){
            setMessage('File must be less than 1MB');
            return
        }
        setLoadingState(true);
        let data = new FormData();
        data.append('user_img', event.target.files[0]);
        learnerUpdateCall(token, data).then((response)=>response.json()).then((json_resp)=>{
            setLoadingState(false);
            setLearnerDetails({...learnerDetails, ...json_resp});
            event.target.reset();
        }).catch((e)=>{
            setLoadingState(false);
            
        })
    }
    return ( 
        <div className="w-100 d-flex text-center justify-content-center flex-column p-3" style={{width:'50px',}}>
            <div className="w-100 text-center">
                <div className="border rounded-circle d-inline-block overflow-hidden" style={{width:'150px',}}>
                <img style={{width:'100%'}} src={learnerDetails.img_url? `${base_url}storage/${learnerDetails.img_url}`:'/avatar.png'} alt="logo" />
                </div>
                
                <FontAwesomeIcon onClick={()=>captureImage()} style={{cursor:'pointer'}} className="position-absolute text-primary" size="2x" icon="camera" />
                <span className={`spinner-grow spinner-grow-sm me-2 text-primary ${loadingState?'':'d-none'}`} role="status" aria-hidden="true"></span>
                <input onChange={handleImageChange} accept="image/png, image/gif, image/jpeg" ref={inputRef} type="file" style={{visibility:'hidden'}} />
            </div>
            <h6 className="text-warning">{message}</h6>
            <h4 className="w-100 text-muted ">{learnerDetails.name}</h4>
        </div>
     );
}
 

const UserProperty = ({learnerDetails, setLearnerDetails, field, type, name, ...props}) => {
    const [showField, setShowField] = useState(false);
    const [eyeCurrentState, setEyeCurrentState] = useState(false);
    const [eyeState, setEyeState] = useState(false);
    const [eyeConfirmState, setEyeConfirmState] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [token] = useStateWithLocalStorage("learner_token");
    

    let handleSubmit = (event)=>{
        event.preventDefault();
        setLoadingState(true);
        if(loadingState){
            return;
        }
        let data = new FormData(event.target);
        let okResponse = false;
        learnerUpdateCall(token, data).then((response)=>{  okResponse = response.ok; return response.json();}).then((json_resp)=>{
            setLoadingState(false);
            if(!okResponse){
                let error = '';
                Object.values(json_resp.errors??[]).forEach(fields => {
                    // Object.values(element);
                    fields.forEach(msg => {
                        error += `${msg} \n`;
                    });
                    
                });
                swal({
                    text: error||json_resp.message,
                    icon: 'warning',
                    timer: 3000,
                    button: false,
                });
                return;
            }
            setLearnerDetails({...learnerDetails, ...json_resp});
            swal({
                text: 'Updated Successfully',
                icon: 'success',
                timer: 1500,
                button: false,
            });
            event.target.reset();
        }).catch((e)=>{
            setLoadingState(false);
            
        })
    }
    return ( 
        <Fragment>
            <h5 className="py-1">
                <span>{field}: </span>
                <small>{learnerDetails[name]}</small>
                <FontAwesomeIcon onClick={()=>setShowField(!showField)} className={ `${showField?'text-primary':'text-secondary'} float-end ${name==='email'?'d-none':''}`} icon="edit" />
                {
                    showField?
                    <div className="mb-3 postion-relative">
                        <form action="" className="position-relative" method="post" onSubmit={handleSubmit}>
                            
                            {type==="password"?

                                <Fragment>
                                    <label className="d-block m-2">Current Password</label>
                                    <div className="position-relative">
                                        <input type={eyeCurrentState?  "text" :type} name={`current_${name}`} className="form-control" required maxLength="255"/>
                                        <FontAwesomeIcon onClick={()=>{ setEyeCurrentState(state=>!state) }} icon={eyeCurrentState?"eye-slash":"eye"} className={`position-absolute top-0 end-0 m-2 text-${eyeCurrentState?"dark":"primary"}`}/>
                                    </div>


                                    <label className="d-block m-2">New Password</label>
                                    <div className="position-relative">
                                        <input type={type==="password" && eyeState?  "text" :type} name={name} className="form-control" required maxLength="255"/>
                                        <FontAwesomeIcon onClick={()=>{ setEyeState(state=>!state) }} icon={eyeState?"eye-slash":"eye"} className={`position-absolute top-0 end-0 m-2 text-${eyeState?"dark":"primary"}`}/>
                                    </div>

                                    <label className="d-block m-2">Password Confirmation</label>
                                    <div className="position-relative">
                                        <FontAwesomeIcon onClick={()=>{ setEyeConfirmState(state=>!state) }} icon={eyeConfirmState?"eye-slash":"eye"} className={`position-absolute top-0 end-0 m-2 text-${eyeConfirmState?"dark":"primary"}`}/>
                                        <input type={eyeConfirmState?  "text" :type} name={`${name}_confirmation`} className="form-control" required maxLength="255"/>
                                    </div>
                                </Fragment>
                            
                            :
                             <div className="position-relative">
                                <input type={type} name={name} className="form-control" required maxLength="255"/>
                            </div>
                            }
                            
                            <div className="w-100 text-end">
                                
                                <span className={`spinner-grow spinner-grow-sm me-2 text-primary ${loadingState?'':'d-none'}`} role="status" aria-hidden="true"></span>
                                <button className="btn btn-primary m-1 text-light">update</button>
                                
                            </div>
                        </form>
                        
                    </div>
                    :<Fragment/>
                }
            </h5>
            
        </Fragment>
     );
}
 
 
export default Profile;