
import { Link } from "react-router-dom";
const LeftList = ({learnerDetails,currentSession, ...props}) => {
    let allSessions = learnerDetails.organization.active_live_sessions;

    let renderAllAppointments = ()=>{
        return allSessions.map((session)=>{
            return <AppiontmentListItem
                        key={session.id}
                        session={session}
                        currentSession={currentSession}
                        
                        />;
        })
    }
    return ( 
        <div className="card pb-5  pt-2 shadow border-0">
            <div className="card-body p-1">
                <ul className="list-group">
                    {renderAllAppointments()}
                </ul>
            </div>
            
        </div>
     );
}

const AppiontmentListItem = ({session, currentSession, ...props}) => {
    
    return ( 
        <Link to={`/live-session/${session.id}`} className="text-decoration-none">
            <li className={`list-group-item border-0 row w-100 m-0 ${currentSession && currentSession.id === parseInt(session.id)?' active':'' }`}>
                <span className="col-2 border p-2 rounded-circle text-bolder fs-5">
                    {session.instructor.name.split(' ')[0]?session.instructor.name.split(' ')[0][0]:''}
                    {session.instructor.name.split(' ')[1]?session.instructor.name.split(' ')[1][0]:''}
                    
                </span>
                <span className="col-10">
                    <small>{session.instructor.name}</small>
                </span>
            </li>
        </Link>
     );
}

export default LeftList;